
import {
    ref,
    watch,
    provide,
    computed,
    onMounted,
    onUnmounted,
    defineComponent
} from 'vue';
import { useStore } from 'vuex';
import { EventType, TargetType, MessageType, WidgetType } from '@/data/protos/enums';
import { verifyEvent } from '@/data/protos/helpers';
import { fetchEvent, fetchEventById, fetchProgram } from '@/services/eventAPI';
import { launchExternalApp, messageListener, closeApp } from 'vizio-vui-tv';
import Snipe from '@/widgets/snipe';
import TemplateApp from '@/widgets/templateApp';
import * as ga from '@/widgets/googleAnalytics';
import LiveApi from '@/services/liveAPI';
import { LiveEventMap, LiveHandlers } from '@/services/models';

export default defineComponent({
    components: {
        Snipe,
        TemplateApp
    },

    setup () {
        const store = useStore();
        const loading = ref(true);
        const liveFireWaiting = ref(false);
        const storedWidget = ref('');
        let retryCount = 0;
        let liveEventMap: LiveEventMap | null = null;
        const eventId = computed(() => store.state.event.id);
        const homeAsset = computed(() => store.state.event.homeAsset);
        const snipeIsLoaded = computed(() => store.state.snipeIsLoaded);
        const renderedWidget = computed(() => store.state.renderedWidget);
        const externalAppTarget = computed(() => store.state.externalAppTarget);
        const liveFire = computed(() => store.state.liveFire);
        let canvasProgramStartTime: any;
        const launchWidget = computed(() =>
            renderedWidget.value
        );

        const storedWidgetTemplateId: any = computed(() => {
            const { SNIPE, TEMPLATE_APP } = WidgetType;
            let id;
            for (const [key, value] of Object.entries<any>(store.state.event.assets)) {
                if ((storedWidget.value === SNIPE && key.includes(SNIPE)) || (storedWidget.value === TEMPLATE_APP && key.includes(TEMPLATE_APP))) {
                    id = value.templateId;
                }
            }
            return id;
        });

        const eventType = computed(() => {
            let type: any;
            const assets = Object.keys(store.state.event.assets);

            if (assets.length === 2) {
                type = EventType.SNIPE_AND_TEMPLATE_APP;
            } else if (assets.length === 1) {
                if (assets[0].includes('snipe')) {
                    type = EventType.SNIPE;
                } else {
                    type = EventType.TEMPLATE_APP;
                }
            }
            return type;
        });

        const clearKeyState = async () => {
            await store.dispatch('keyState', '');
        };

        const setKeyState = async (keyEvent: string) => {
            if (liveFireWaiting.value) {
                closeApp();
            }
            await store.dispatch('keyState', keyEvent);
            await clearKeyState();
        };

        const setRenderedWidget = async () => {
            const { SNIPE, TEMPLATE_APP } = WidgetType;
            homeAsset.value?.includes(SNIPE)
                ? await store.dispatch('renderedWidget', SNIPE)
                : await store.dispatch('renderedWidget', TEMPLATE_APP);
        };

        const currentTimeStamp = () => {
            const currentTime = new Date().toISOString();
            return new Date(currentTime).getTime();
        };

        const getProgramStartOffset = (programLength = 0) => {
            const offset = new URLSearchParams(window.location.search).get('offset');
            let programStartOffset = 0;
            if (offset && !isNaN(parseInt(offset))) {
                programStartOffset = parseInt(offset);
            }
            if (!programStartOffset) {
                // Remove this if statement when MediaTime from What's On is accurate
                const currentTime = new Date(currentTimeStamp());
                const currentMin = currentTime.getMinutes();
                const programLengthMins = Math.floor(programLength / 60000);
                const minutes = currentMin >= programLengthMins ? currentMin - programLengthMins : currentMin;
                programStartOffset = minutes * 60 + currentTime.getSeconds();
            }
            return programStartOffset;
        };

        const launchEvent = async () => {
            store.dispatch('systemNotification', {
                active: homeAsset.value?.includes('systemNotification'),
                listenerWidget: homeAsset.value?.includes('listenerWidget'),
                votingWidget: homeAsset.value?.includes('votingWidget')
            });
            const assets = Object.keys(store.state.event.assets);

            if ((eventType.value === 'SnipeAndTemplateApp' && assets[1].includes('votingWidget')) ||
                (eventType.value === 'TemplateApp' && assets[0].includes('votingWidget'))) {
                store.dispatch('templateAppVoting', true);
            }

            setRenderedWidget();
        };

        const load = async (event: any) => {
            if (event instanceof Error) {
                // eslint-disable-next-line no-console
                console.error(`Event failed to load: ${event.message}`);
                return;
            }

            verifyEvent(event);

            if (event.offset && event.trigger.triggerType === 'program') {
                const programStartOffset = getProgramStartOffset(event.programLength);
                const launchOffset = event.offset || 0;
                const totalOffset = launchOffset * 1000 - programStartOffset * 1000;
                if (launchOffset && totalOffset < 0) {
                    return;
                }

                if (totalOffset) {
                    await new Promise(resolve => setTimeout(resolve, totalOffset));
                }
            }

            await store.dispatch('loadEvent', event);
            loading.value = false;
            launchEvent();
        };

        const showEvent = (event: any) => {
            const testing = new URLSearchParams(window.location.search).get('testing');
            if (testing === 'true') {
                // testing=true param allows QA to bypass timer logic
                return true;
            }

            if (localStorage.getItem(event.id)) {
                const storedItem: any = localStorage.getItem(event.id);
                const time: any = JSON.parse(storedItem).time;
                if (event.trigger.triggerType === 'program' && currentTimeStamp() - time < 3600000) {
                    return false;
                } else if (event.trigger.triggerType === 'commercial' && currentTimeStamp() - time < 900000) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        };

        const loadEvent = async () => {
            const event = await fetchEvent();
            if (showEvent(event)) {
                load(event);
            } else {
                closeApp();
            }
        };

        const loadEventById = async (canvasEventId: string) => {
            liveFireWaiting.value = false;
            const event = await fetchEventById(false, '', canvasEventId);
            load(event);
            loading.value = false;
            setTimeout(() => {
                liveFireWaiting.value = true;
            }, 30000);
        };

        const handleCtaButtonPress = async (targetType: string) => {
            const { SNIPE, TEMPLATE_APP, TVAPP } = TargetType;
            switch (targetType) {
                case SNIPE:
                    await store.dispatch('renderedWidget', SNIPE);
                    break;
                case TEMPLATE_APP:
                    await store.dispatch('renderedWidget', TEMPLATE_APP);
                    break;
                case TVAPP:
                    externalAppTarget.value && launchExternalApp(externalAppTarget.value);
                    break;
            }
        };

        const loadWhatsOnData = async (data: any) => {
            ga.load({ source: eventType.value, ...data });
        };

        const close = () => {
            messageListener(MessageType.KEY_EVENT, setKeyState, false);
            messageListener(MessageType.WHATS_ON, loadWhatsOnData, false);
            if (liveFire.value) {
                liveFireWaiting.value = true;
            } else {
                const storedItem = {
                    time: currentTimeStamp(),
                    id: eventId.value
                };
                localStorage.setItem(
                    eventId.value,
                    JSON.stringify(storedItem)
                );
                if (liveEventMap) {
                    LiveApi.unsubscribe(liveEventMap);
                }
                closeApp();
            }
        };

        const setResolution = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const height = urlParams.get('height');
            store.dispatch('resolution', Number(height) > 720 ? 1080 : 720);
        };

        const currentOffset = () => {
            return currentTimeStamp() - canvasProgramStartTime;
        };

        const loadLiveFireEvent = (launchLiveFireEvent: any) => {
            const canvasEventId = launchLiveFireEvent.canvasEventId;
            loading.value = true;
            loadEventById(canvasEventId);
        };

        const subscribeLiveEvent = async (canvasProgramId: string, delayOffset: number) => {
            try {
                const liveFireEventMap: LiveEventMap = {
                    handler: LiveHandlers.LIVEFIRE,
                    onOpen: null,
                    onMessage: async (action: string, data: {[k: string]: any}) => {
                        if (action !== 'End') {
                            const launchLiveFireEvent = data;
                            const canvasLaunchOffset = launchLiveFireEvent?.launchOffset;
                            const bufferTime = launchLiveFireEvent?.bufferTime + delayOffset + 30000;
                            const eventDelay = Math.abs(canvasLaunchOffset - currentOffset());
                            if (eventDelay <= bufferTime) {
                                if (delayOffset > 30000) {
                                    await new Promise(resolve => setTimeout(resolve, delayOffset));
                                }
                                loadLiveFireEvent(launchLiveFireEvent);
                            }
                        } else {
                            closeApp();
                        }
                    },
                    onClose: async () => {
                        if (retryCount <= 2) {
                            retryCount += 1;
                            liveEventMap = await subscribeLiveEvent(canvasProgramId, delayOffset);
                        } else {
                            closeApp();
                        }
                    }
                };
                await LiveApi.subscribe(liveFireEventMap);
                return liveFireEventMap;
            } catch (err) {
                return liveEventMap;
            }
        };

        const publishLaunchedEvents = (publishedEvents: any, programStartOffset: number) => {
            let waitTime = 0;
            publishedEvents.forEach((event: any) => {
                if (event.event?.launchOffset > 0) {
                    if (event.event?.launchOffset >= programStartOffset) {
                        waitTime += event.event.launchOffset - programStartOffset;
                    } else {
                        return;
                    }
                } else {
                    waitTime += 30000;
                }
                setTimeout(() => {
                    if (event.event.action === 'End') {
                        closeApp();
                    } else {
                        loadEventById(event.event.canvasEventId);
                    }
                }, waitTime);
            });
        };

        const subscribeDelayedProgram = async (programStartOffset: number, program: any, delayOffset: number) => {
            const publishedEvents = program.published_events;
            if (publishedEvents) {
                publishLaunchedEvents(publishedEvents, programStartOffset);
                setTimeout(async () => {
                    const updatedProgram = await fetchProgram(program.canvasProgramId);
                    if (updatedProgram?.published_events.length > publishedEvents?.length) {
                        subscribeDelayedProgram(currentTimeStamp() - canvasProgramStartTime - delayOffset, updatedProgram, delayOffset);
                    } else {
                        liveEventMap = await subscribeLiveEvent(program.canvasProgramId, delayOffset);
                    }
                }, delayOffset);
            } else {
                liveEventMap = await subscribeLiveEvent(program.canvasProgramId, delayOffset);
            }
        };

        const subscribeDvrProgram = (program: any, programStartOffset: number) => {
            const publishedEvents = program.published_events;
            publishLaunchedEvents(publishedEvents, programStartOffset);
        };

        const liveFireProgramSetup = async (paths: any) => {
            const canvasProgramId = paths[1];
            const programStartOffset = getProgramStartOffset();
            if (canvasProgramId) {
                const program = await fetchProgram(canvasProgramId);
                if (program) {
                    if (program.totalRunTime && program.totalRunTime > 0) {
                        subscribeDvrProgram(program, programStartOffset);
                    } else {
                        canvasProgramStartTime = new Date(program.startTime).getTime();
                        const delayOffset = currentOffset() - programStartOffset;
                        if (delayOffset > 30000) {
                            subscribeDelayedProgram(programStartOffset, program, delayOffset - 30000);
                        } else {
                            liveEventMap = await subscribeLiveEvent(canvasProgramId, 0);
                        }
                    }
                }
            }
        };

        onMounted(() => {
            setResolution();
            messageListener(MessageType.KEY_EVENT, setKeyState, true);
            messageListener(MessageType.WHATS_ON, loadWhatsOnData, true);
            const wholeStr = window.location.pathname.substring(1);
            const paths = wholeStr.split('/');
            const firstStr = paths[0];
            const canvasProgramId = paths[1];
            LiveApi.setCanvasProgramId(canvasProgramId);
            if (firstStr === 'liveFire') {
                store.dispatch('liveFire', true);
                liveFireProgramSetup(paths);
            } else {
                loadEvent();
            }
        });

        onUnmounted(async () => {
            await LiveApi.disconnect();
        });

        watch(() => store.state.ctaButtonPress, async (targetType) => {
            if (targetType === 'close') {
                close();
            } else {
                handleCtaButtonPress(targetType);
            }
        });

        provide('store', store);

        return {
            load,
            close,
            loading,
            eventId,
            eventType,
            homeAsset,
            showEvent,
            loadEvent,
            WidgetType,
            launchWidget,
            storedWidget,
            clearKeyState,
            snipeIsLoaded,
            renderedWidget,
            setRenderedWidget,
            launchExternalApp,
            handleCtaButtonPress,
            getProgramStartOffset,
            storedWidgetTemplateId,
            liveFireWaiting,
            launchEvent
        };
    }
});
