import { store } from '@/store';
import { useGtag } from 'vue-gtag-next';

const { pageview, event } = useGtag();

export const track = (eventName: any, category: string) => {
    event(eventName, {
        'event_category': category
    });
};

export const load = (data: any) => {
    const show = data.Program[0]?.ContentId;
    const adUnit = data.Commercial[0]?.ContentId;
    const channel = data.Program[0]?.ChannelId;
    const deviceId = data.TVAttributes?.tvid;
    const eventTitle = store.state.event?.title;

    pageview(data.source);
    event('show_dimension', { show });
    event('adUnit_dimension', { adUnit });
    event('channel_dimension', { channel });
    event('deviceId_dimension', { deviceId });
    event('eventTitle_dimension', { eventTitle });
};
