
import { defineComponent, ref, watch, computed, inject } from 'vue';
import { ButtonNavigation, optOut } from 'vizio-vui-tv';
import SnipeButton from '@/widgets/snipe/components/SnipeButton/SnipeButton.vue';
import SystemNotificationText from '@/widgets/snipe/components/SystemNotificationText/SystemNotificationText.vue';

export default defineComponent({
    name: 'NotificationSettings',
    props: {
        isTemplateApp: Boolean
    },

    components: {
        SnipeButton,
        SystemNotificationText
    },

    setup (props, { emit }) {
        const store = inject<any>('store');
        const notificationRef = ref(null);
        const buttonNav = new ButtonNavigation(notificationRef);
        const keyState = computed(() => store.state.keyState);
        const timeout: any = ref(0);
        const notificationSettingsOpen = computed(() => store.state.notificationSettingsOpen);
        const optOutStage = ref(0);
        const buttons = ref([
            {
                rendered: true,
                type: 'back',
                targetType: '',
                isInitialFocus: true,
                focusable: true,
                normalStyle: {
                    label: 'Back'
                }
            },
            {
                rendered: true,
                type: 'optOut',
                targetType: 'optOut',
                focusable: true,
                normalStyle: {
                    label: 'Turn Off'
                }
            }
        ]);

        const dynamicItems = ref({
            textTop: 'Jump View Notifications',
            textBottom: 'Copy needs to be written describing the benefits of this.',
            iconRing: '',
            iconInner: '',
            alexaListening: false,
            backArrow: true
        });

        const close = async () => {
            await buttonNav.unsetElements();
            if (optOutStage.value !== 2) {
                optOutStage.value = 0;
                dynamicItems.value.textTop = 'Jump View Notifications';
                dynamicItems.value.textBottom = 'Copy needs to be written describing the benefits of this.';
                buttons.value[1].normalStyle.label = 'Turn Off';
                buttons.value[1].type = 'optOut';
            }
            emit('close-settings', true);
        };

        const onOptOut = async () => {
            if (optOutStage.value === 0) {
                dynamicItems.value.textTop = 'Are you sure?';
                dynamicItems.value.textBottom = 'You will no longer see watching suggestions.';
                buttons.value[1].normalStyle.label = 'Yes, I\'m Sure';
                optOutStage.value = 1;
                await buttonNav.unsetElements();
                await buttonNav.initialize();
                return false;
            }

            if (optOutStage.value === 1) {
                dynamicItems.value.textTop = 'You will not see any more future Jump Views.';
                dynamicItems.value.textBottom = 'To turn this feature back on go to SmartCast settings.';
                buttons.value[0].rendered = false;
                buttons.value[1].rendered = false;
                buttons.value[0].focusable = false;
                buttons.value[1].focusable = false;
                buttonNav.resetFocus(0);
                optOutStage.value = 2;
                await optOut(true);
                clearTimeout(timeout.value);
                timeout.value = setTimeout(() => {
                    store.dispatch('ctaButtonPress', 'close');
                }, 5000);
            }
        };

        watch(() => notificationSettingsOpen.value, (val: boolean) => {
            // watching for animate-in and changing button focus
            clearTimeout(timeout.value);
            timeout.value = setTimeout(() => {
                val && buttonNav.initialize();
            }, 10);
        });

        watch(() => keyState.value, (keyEvent) => {
            if (keyEvent && notificationSettingsOpen.value) {
                buttonNav.onPress(keyEvent);
                emit('run-timestamp');
            }
        });

        return {
            notificationRef,
            buttons,
            close,
            onOptOut,
            dynamicItems,
            notificationSettingsOpen,
            optOutStage
        };
    }
});
