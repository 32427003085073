import { createApp } from 'vue';
import App from './App.vue';
import { store } from './store';
import router from './router/index';
import gtag from 'vue-gtag-next';

const app = createApp(App);

app.use(store);
app.use(router);
app.use(gtag, {
    property: {
        id: 'UA-178168516-1',
        params: {
            linker: { domains: [document.referrer] },
            'custom_map': {
                dimension1: 'deviceId',
                dimension2: 'utc',
                dimension3: 'timeZone',
                dimension4: 'channel',
                dimension5: 'show',
                dimension6: 'eventTitle',
                dimension7: 'adUnit'
            }
        }
    }
}).mount('#app');
