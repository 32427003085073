
import { defineComponent, inject, onMounted, computed } from 'vue';

export default defineComponent({
    setup () {
        const store = inject<any>('store');
        const templateAppAssets = inject<any>('templateAppAssets');
        const resolution = computed(() => store.state.resolution);
        const assetBaseUrl = 'https://static.vizio-is.com/prod/assets/';

        onMounted(() => {
            const bgImage = new Image();
            bgImage.onload = null; // Garbage collect content.backgroundImage.
            bgImage.src = assetBaseUrl + templateAppAssets?.modalContent?.backgroundImage;
        });

        return {
            resolution,
            modalStyling: templateAppAssets?.style,
            content: templateAppAssets?.modalContent,
            buttonStyling: templateAppAssets?.buttons,
            backgroundImage: templateAppAssets?.modalContent?.backgroundImage && 'url(' + assetBaseUrl + templateAppAssets.modalContent.backgroundImage + ')'
        };
    }
});
