

import { defineComponent } from 'vue';
import {
  vizio,
  clear,
  gear,
  backspace,
  backArrow
} from './SvgIconsList';

export default defineComponent({

  name: 'SvgIcon',
  props: {
    icon: {
      type: String
    }
  },

  data () {
    return {
      svg: ''
    };
  },

  created () {
    switch (this.icon) {
      case 'vizio': this.svg = vizio; break;
      case 'clear': this.svg = clear; break;
      case 'gear': this.svg = gear; break;
      case 'back': this.svg = backspace; break;
      case 'back-arrow': this.svg = backArrow; break;
    }
  }
});

