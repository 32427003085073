
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'VotingResults',
    props: {
        results: Array,
        totalVotes: Number,
        isTemplateApp: Boolean
    },

    computed: {
        totalVotesDisplayed () { // Make this more accurate once we know what the general vote totals will be
            let total: any = this.totalVotes?.toString();
            if (total.length > 6) {
                total = total.replace(/.{0,6}$/, 'M');
            }

            if (total.length > 3 && total.length < 6) {
                total = total.replace(/.{0,3}$/, 'K');
            }

            return total;
        }
    }
});
