
import SvgIcon from '@/assets/svg/SvgIcon.vue';
import { convertStylesTo720 } from '@/helpers/helpers';
import { defineComponent, computed, inject, watch, ref } from 'vue';

export default defineComponent({
    components: { SvgIcon },
    props: {
        buttonData: {
            type: Object,
            required: true
        },
        isSystemNotification: Boolean,
        isVotingWidget: Boolean,
        icon: String,
        index: Number,
        focusable: {
            type: Boolean,
            default: true
        }
    },

    setup (props, { emit }) {
        const store = inject<any>('store');
        const buttonRef: any = ref(null);
        const keyState = computed(() => store.state.keyState);
        const assetUrl = computed(() => store.state.assetBaseUrl + 'buttons/');
        const resolution = computed(() => store.state.resolution);
        const button: any = computed(() => props.buttonData).value;
        let focusStyle;
        let buttonNormalStyle;
        let buttonFocusStyle;
        let cssProps;

        if (!props.isSystemNotification) {
            focusStyle = computed(() => {
                // if normalStyle has any attributes that aren't in focusStyle, copy them into focusStyle
                if (button.normalStyle && button.focusStyle) {
                    const normalStyle = { ...button.normalStyle };
                    return Object.assign(normalStyle, button.focusStyle);
                }
                return {};
            }).value;

            buttonNormalStyle = resolution.value === 720 ? convertStylesTo720(button.normalStyle) : button.normalStyle;
            buttonFocusStyle = resolution.value === 720 ? convertStylesTo720(focusStyle) : focusStyle;

            cssProps = {
                // normal styling
                '--button-height': buttonNormalStyle?.height,
                '--button-width': buttonNormalStyle?.width,
                '--button-offset-y': buttonNormalStyle?.top,
                '--button-offset-x': buttonNormalStyle?.left,
                '--button-bottom': buttonNormalStyle?.bottom,
                '--button-right': buttonNormalStyle?.right,
                '--background-color': !buttonNormalStyle?.backgroundImage ? buttonNormalStyle?.backgroundColor : null,
                '--background-image': buttonNormalStyle?.backgroundImage && 'url(' + assetUrl.value + buttonNormalStyle.backgroundImage + ')',
                '--font-color': !buttonNormalStyle?.backgroundImage ? buttonNormalStyle.fontColor : null,

                // focus styling
                '--button-height-focus': buttonFocusStyle?.height,
                '--button-width-focus': buttonFocusStyle?.width,
                '--button-offset-y-focus': buttonFocusStyle?.top,
                '--button-offset-x-focus': buttonFocusStyle?.left,
                '--button-bottom-focus': buttonFocusStyle?.bottom,
                '--button-right-focus': buttonFocusStyle?.right,
                '--background-color-focus': !buttonFocusStyle?.backgroundImage ? buttonFocusStyle?.backgroundColor : null,
                '--background-image-focus': buttonFocusStyle?.backgroundImage && 'url(' + assetUrl.value + buttonFocusStyle.backgroundImage + ')',
                '--font-color-focus': !buttonFocusStyle?.backgroundImage ? buttonFocusStyle?.fontColor : null,

                // animations
                '--animate-in-place': button.animation?.animateInPlace && button.animation?.animateInPlace,
                '--animation-delay': button.animation?.animationDelay && button.animation?.animateInPlace,
                '--animation-duration': button.animation?.animationDuration && button.animation?.animateInPlace
            };
        }

        const buttonLabel = button?.normalStyle?.label;

        const handleKeyEvent = async (keyEvent: string) => {
            if (
                keyEvent === 'enter' &&
                buttonRef.value.classList.contains('focused')
            ) {
                switch (button.type) {
                    case 'ctaButton':
                        button.target && await store.dispatch('externalAppTarget', button.target);
                        await store.dispatch('ctaButtonPress', button.targetType);
                        break;
                    case 'closeButton':
                        await store.dispatch('ctaButtonPress', 'close');
                        break;
                    case 'meButton':
                        store.dispatch('meOpen', true);
                        break;
                    case 'votingButton':
                        emit('vote-pressed', button.normalStyle.label);
                        break;
                    case 'icon':
                        emit('icon-click', true);
                        break;
                    case 'back':
                        emit('back-click', true);
                        break;
                    case 'optOut':
                        emit('opt-out', true);
                }
            }
        };

        watch(() => keyState.value, (keyEvent) => {
            if (keyEvent) {
                handleKeyEvent(keyEvent);
            }
        });

        return {
            cssProps,
            buttonRef,
            buttonLabel,
            handleKeyEvent,
            buttonType: button.type,
            rendered: button.rendered,
            targetType: button.targetType,
            isInitialFocus: button.isInitialFocus
        };
    }
});
