export const convertStylesTo720 = (styles: any) => {
    Object.keys(styles).forEach((key: any) => {
        if (styles[key].includes('px')) {
            styles[key] = Math.round((parseInt(styles[key].replace(/px/, '')) * 0.6666666666666)) + 'px';
        }
    });
    return styles;
};

export function isString (x: any): x is string {
    return typeof x === 'string';
}

export function isNumber (x: any): x is number {
    return typeof x === 'number';
}

export function isBool (x: any): x is boolean {
    return typeof x === 'boolean';
}

export const toNumOrZero = (x: string) => {
    const n = Number(x || '0');
    return isNaN(n) ? 0 : n;
};

// if "true", "1", 1, true => true, else => false
export const toBool = (value: any): boolean => {
    if (value == null) {
        return false;
    } else if (isBool(value)) {
        return value;
    } else if (isNumber(value) || isString(value)) {
        return Boolean(value);
    } else {
        return false;
    }
};
