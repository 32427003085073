
import { defineComponent, computed, PropType, inject, watch, ref, onMounted } from 'vue';
import { convertStylesTo720 } from '@/helpers/helpers';

export default defineComponent({
    props: {
        buttonData: {
            type: Object as PropType<any>,
            required: true
        },
        isVotingWidget: Boolean,
        showVotingResults: Boolean
    },

    setup (props, { emit }) {
        const store = inject<any>('store');
        const buttonRef: any = ref(null);
        const keyState = computed(() => store.state.keyState);
        const assetUrl = computed(() => store.state.assetBaseUrl + 'buttons/');
        const resolution = computed(() => store.state.resolution);
        const button: any = computed(() => props.buttonData).value;
        const isDisplayed = computed(() => store.state.renderedWidget === 'templateApp');

        const focusStyle: any = computed(() => {
            // if normalStyle has any attributes that aren't in focusStyle, copy them into focusStyle
            if (button.normalStyle && button.focusStyle) {
                const normalStyle = { ...button.normalStyle };
                return Object.assign(normalStyle, button.focusStyle);
            }
            return {};
        }).value;

        const fallbackNormalLabel = button.type === 'tcButton' ? 'Terms & Conditions' : button.normalStyle?.label || 'Click Here';
        const fallbackFocusLabel = button.type === 'tcButton' ? 'Terms & Conditions' : button.focusStyle?.label || 'Click Here';
        const hasBackgroundImage = Boolean(button.normalStyle?.backgroundImage);

        const normalLabel = computed(() => { return button.normalStyle?.backgroundImage ? '' : fallbackNormalLabel; });
        const focusLabel = computed(() => { return focusStyle?.backgroundImage ? '' : fallbackFocusLabel; });
        const isSuiButton = computed(() =>
            props.isVotingWidget ||
            (!hasBackgroundImage && button.type !== 'tcButton' && button.targetType !== 'qr-code')
        );

        const buttonNormalStyle = resolution.value === 720 ? convertStylesTo720(button.normalStyle) : button.normalStyle;
        const buttonFocusStyle = resolution.value === 720 ? convertStylesTo720(focusStyle) : focusStyle;

        const normalBackgroundColor = computed(() => {
            let color;
            if (!hasBackgroundImage) {
                if (button.type === 'tcButton') {
                    color = buttonNormalStyle?.backgroundColor || 'transparent';
                } else {
                    color = buttonNormalStyle?.backgroundColor || '#000000';
                }
            }
            return color;
        });

        let cssProps;

        if (!isSuiButton.value || button.type === 'tcButton') {
            cssProps = {
                // normal styling
                '--button-height': buttonNormalStyle?.height || '60px',
                '--button-width': buttonNormalStyle?.width || '280px',
                '--button-offset-y': buttonNormalStyle?.top || '200px',
                '--button-offset-x': buttonNormalStyle?.left || '0px',
                '--button-bottom': buttonNormalStyle?.bottom || 'auto',
                '--button-right': buttonNormalStyle?.right || 'auto',
                '--background-image': buttonNormalStyle?.backgroundImage && 'url(' + assetUrl.value + buttonNormalStyle.backgroundImage + ')',
                '--background-color': normalBackgroundColor.value || buttonNormalStyle?.backgroundColor,
                '--font-color': buttonNormalStyle?.fontColor || '#ffffff',

                // focus styling
                '--button-height-focus': buttonFocusStyle?.height || '60px',
                '--button-width-focus': buttonFocusStyle?.width || '280px',
                '--button-offset-y-focus': buttonFocusStyle?.top || '200px',
                '--button-offset-x-focus': buttonFocusStyle?.left || '0px',
                '--button-bottom-focus': buttonFocusStyle?.bottom || 'auto',
                '--button-right-focus': buttonFocusStyle?.right || 'auto',
                '--background-image-focus': buttonFocusStyle?.backgroundImage && 'url(' + assetUrl.value + buttonFocusStyle.backgroundImage + ')',
                '--background-color-focus': !buttonFocusStyle?.backgroundImage ? buttonFocusStyle?.backgroundColor || '#ffffff' : '',
                '--font-color-focus': buttonFocusStyle?.fontColor || '#000000',

                // animations
                '--button-animation-name': button.animation?.animateInPlace && button.animation?.animationName,
                '--button-animation-delay': button.animation?.animateInPlace && button.animation?.animationDelay,
                '--button-animation-duration': button.animation?.animateInPlace && button.animation?.animationDuration
            };
        } else if (isSuiButton.value && !props.isVotingWidget) {
            cssProps = {
                // Subtract 20px from these values to account for margin
                '--button-offset-y': (buttonNormalStyle?.top && parseInt(buttonNormalStyle.top) - 20 + 'px') || '200px',
                '--button-offset-y-focus': (buttonFocusStyle?.top && parseInt(buttonFocusStyle.top) - 20 + 'px') || '200px'
            };
        }

        const handleKeyEvent = async (keyEvent: string) => {
            if (
                keyEvent === 'enter' &&
                buttonRef.value.classList.contains('focused')
            ) {
                if (button.type === 'tcButton') {
                    store.dispatch('tcOpen', true);
                } else if (button.type === 'meButton') {
                    store.dispatch('meOpen', true);
                } else if (button.type === 'votingButton') {
                    emit('vote-pressed', button.normalStyle.label);
                } else {
                    button.target && await store.dispatch('externalAppTarget', button.target);
                    await store.dispatch('ctaButtonPress', button.targetType);
                }
            }
        };

        watch(() => keyState.value, (keyEvent) => {
            if (keyEvent && isDisplayed.value) {
                handleKeyEvent(keyEvent);
            }
        });

        onMounted(() => {
            if (props.showVotingResults) {
                buttonRef.value.classList.add('focused');
            }
        });

        return {
            cssProps,
            buttonRef,
            resolution,
            focusStyle,
            focusLabel,
            normalLabel,
            isSuiButton,
            handleKeyEvent,
            type: button.type,
            normalBackgroundColor,
            rendered: button.rendered,
            targetType: button.targetType
        };
    }
});
