/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const Animation = $root.Animation = (() => {

    /**
     * Properties of an Animation.
     * @exports IAnimation
     * @interface IAnimation
     * @property {string|null} [enter] Animation enter
     * @property {string|null} [animate] Animation animate
     * @property {string|null} [animatePauseLength] Animation animatePauseLength
     * @property {boolean|null} [animateInPlace] Animation animateInPlace
     * @property {string|null} [animationDelay] Animation animationDelay
     * @property {string|null} [animationDuration] Animation animationDuration
     * @property {string|null} [animationName] Animation animationName
     */

    /**
     * Constructs a new Animation.
     * @exports Animation
     * @classdesc Represents an Animation.
     * @implements IAnimation
     * @constructor
     * @param {IAnimation=} [properties] Properties to set
     */
    function Animation(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Animation enter.
     * @member {string} enter
     * @memberof Animation
     * @instance
     */
    Animation.prototype.enter = "";

    /**
     * Animation animate.
     * @member {string} animate
     * @memberof Animation
     * @instance
     */
    Animation.prototype.animate = "";

    /**
     * Animation animatePauseLength.
     * @member {string} animatePauseLength
     * @memberof Animation
     * @instance
     */
    Animation.prototype.animatePauseLength = "";

    /**
     * Animation animateInPlace.
     * @member {boolean} animateInPlace
     * @memberof Animation
     * @instance
     */
    Animation.prototype.animateInPlace = false;

    /**
     * Animation animationDelay.
     * @member {string} animationDelay
     * @memberof Animation
     * @instance
     */
    Animation.prototype.animationDelay = "";

    /**
     * Animation animationDuration.
     * @member {string} animationDuration
     * @memberof Animation
     * @instance
     */
    Animation.prototype.animationDuration = "";

    /**
     * Animation animationName.
     * @member {string} animationName
     * @memberof Animation
     * @instance
     */
    Animation.prototype.animationName = "";

    /**
     * Creates a new Animation instance using the specified properties.
     * @function create
     * @memberof Animation
     * @static
     * @param {IAnimation=} [properties] Properties to set
     * @returns {Animation} Animation instance
     */
    Animation.create = function create(properties) {
        return new Animation(properties);
    };

    /**
     * Encodes the specified Animation message. Does not implicitly {@link Animation.verify|verify} messages.
     * @function encode
     * @memberof Animation
     * @static
     * @param {IAnimation} message Animation message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Animation.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.enter != null && Object.hasOwnProperty.call(message, "enter"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.enter);
        if (message.animate != null && Object.hasOwnProperty.call(message, "animate"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.animate);
        if (message.animatePauseLength != null && Object.hasOwnProperty.call(message, "animatePauseLength"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.animatePauseLength);
        if (message.animateInPlace != null && Object.hasOwnProperty.call(message, "animateInPlace"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.animateInPlace);
        if (message.animationDelay != null && Object.hasOwnProperty.call(message, "animationDelay"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.animationDelay);
        if (message.animationDuration != null && Object.hasOwnProperty.call(message, "animationDuration"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.animationDuration);
        if (message.animationName != null && Object.hasOwnProperty.call(message, "animationName"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.animationName);
        return writer;
    };

    /**
     * Encodes the specified Animation message, length delimited. Does not implicitly {@link Animation.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Animation
     * @static
     * @param {IAnimation} message Animation message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Animation.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an Animation message from the specified reader or buffer.
     * @function decode
     * @memberof Animation
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Animation} Animation
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Animation.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Animation();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.enter = reader.string();
                break;
            case 2:
                message.animate = reader.string();
                break;
            case 3:
                message.animatePauseLength = reader.string();
                break;
            case 4:
                message.animateInPlace = reader.bool();
                break;
            case 5:
                message.animationDelay = reader.string();
                break;
            case 6:
                message.animationDuration = reader.string();
                break;
            case 7:
                message.animationName = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an Animation message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Animation
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Animation} Animation
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Animation.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an Animation message.
     * @function verify
     * @memberof Animation
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Animation.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.enter != null && message.hasOwnProperty("enter"))
            if (!$util.isString(message.enter))
                return "enter: string expected";
        if (message.animate != null && message.hasOwnProperty("animate"))
            if (!$util.isString(message.animate))
                return "animate: string expected";
        if (message.animatePauseLength != null && message.hasOwnProperty("animatePauseLength"))
            if (!$util.isString(message.animatePauseLength))
                return "animatePauseLength: string expected";
        if (message.animateInPlace != null && message.hasOwnProperty("animateInPlace"))
            if (typeof message.animateInPlace !== "boolean")
                return "animateInPlace: boolean expected";
        if (message.animationDelay != null && message.hasOwnProperty("animationDelay"))
            if (!$util.isString(message.animationDelay))
                return "animationDelay: string expected";
        if (message.animationDuration != null && message.hasOwnProperty("animationDuration"))
            if (!$util.isString(message.animationDuration))
                return "animationDuration: string expected";
        if (message.animationName != null && message.hasOwnProperty("animationName"))
            if (!$util.isString(message.animationName))
                return "animationName: string expected";
        return null;
    };

    /**
     * Creates an Animation message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Animation
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Animation} Animation
     */
    Animation.fromObject = function fromObject(object) {
        if (object instanceof $root.Animation)
            return object;
        let message = new $root.Animation();
        if (object.enter != null)
            message.enter = String(object.enter);
        if (object.animate != null)
            message.animate = String(object.animate);
        if (object.animatePauseLength != null)
            message.animatePauseLength = String(object.animatePauseLength);
        if (object.animateInPlace != null)
            message.animateInPlace = Boolean(object.animateInPlace);
        if (object.animationDelay != null)
            message.animationDelay = String(object.animationDelay);
        if (object.animationDuration != null)
            message.animationDuration = String(object.animationDuration);
        if (object.animationName != null)
            message.animationName = String(object.animationName);
        return message;
    };

    /**
     * Creates a plain object from an Animation message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Animation
     * @static
     * @param {Animation} message Animation
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Animation.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.enter = "";
            object.animate = "";
            object.animatePauseLength = "";
            object.animateInPlace = false;
            object.animationDelay = "";
            object.animationDuration = "";
            object.animationName = "";
        }
        if (message.enter != null && message.hasOwnProperty("enter"))
            object.enter = message.enter;
        if (message.animate != null && message.hasOwnProperty("animate"))
            object.animate = message.animate;
        if (message.animatePauseLength != null && message.hasOwnProperty("animatePauseLength"))
            object.animatePauseLength = message.animatePauseLength;
        if (message.animateInPlace != null && message.hasOwnProperty("animateInPlace"))
            object.animateInPlace = message.animateInPlace;
        if (message.animationDelay != null && message.hasOwnProperty("animationDelay"))
            object.animationDelay = message.animationDelay;
        if (message.animationDuration != null && message.hasOwnProperty("animationDuration"))
            object.animationDuration = message.animationDuration;
        if (message.animationName != null && message.hasOwnProperty("animationName"))
            object.animationName = message.animationName;
        return object;
    };

    /**
     * Converts this Animation to JSON.
     * @function toJSON
     * @memberof Animation
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Animation.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return Animation;
})();

export const Button = $root.Button = (() => {

    /**
     * Properties of a Button.
     * @exports IButton
     * @interface IButton
     * @property {IStyle|null} [normalStyle] Button normalStyle
     * @property {IStyle|null} [focusStyle] Button focusStyle
     * @property {IAnimation|null} [animation] Button animation
     * @property {boolean|null} [rendered] Button rendered
     * @property {boolean|null} [isInitialFocus] Button isInitialFocus
     * @property {string|null} [type] Button type
     * @property {string|null} [target] Button target
     * @property {string|null} [targetType] Button targetType
     * @property {string|null} [code] Button code
     */

    /**
     * Constructs a new Button.
     * @exports Button
     * @classdesc Represents a Button.
     * @implements IButton
     * @constructor
     * @param {IButton=} [properties] Properties to set
     */
    function Button(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Button normalStyle.
     * @member {IStyle|null|undefined} normalStyle
     * @memberof Button
     * @instance
     */
    Button.prototype.normalStyle = null;

    /**
     * Button focusStyle.
     * @member {IStyle|null|undefined} focusStyle
     * @memberof Button
     * @instance
     */
    Button.prototype.focusStyle = null;

    /**
     * Button animation.
     * @member {IAnimation|null|undefined} animation
     * @memberof Button
     * @instance
     */
    Button.prototype.animation = null;

    /**
     * Button rendered.
     * @member {boolean} rendered
     * @memberof Button
     * @instance
     */
    Button.prototype.rendered = false;

    /**
     * Button isInitialFocus.
     * @member {boolean} isInitialFocus
     * @memberof Button
     * @instance
     */
    Button.prototype.isInitialFocus = false;

    /**
     * Button type.
     * @member {string} type
     * @memberof Button
     * @instance
     */
    Button.prototype.type = "";

    /**
     * Button target.
     * @member {string} target
     * @memberof Button
     * @instance
     */
    Button.prototype.target = "";

    /**
     * Button targetType.
     * @member {string} targetType
     * @memberof Button
     * @instance
     */
    Button.prototype.targetType = "";

    /**
     * Button code.
     * @member {string} code
     * @memberof Button
     * @instance
     */
    Button.prototype.code = "";

    /**
     * Creates a new Button instance using the specified properties.
     * @function create
     * @memberof Button
     * @static
     * @param {IButton=} [properties] Properties to set
     * @returns {Button} Button instance
     */
    Button.create = function create(properties) {
        return new Button(properties);
    };

    /**
     * Encodes the specified Button message. Does not implicitly {@link Button.verify|verify} messages.
     * @function encode
     * @memberof Button
     * @static
     * @param {IButton} message Button message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Button.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.normalStyle != null && Object.hasOwnProperty.call(message, "normalStyle"))
            $root.Style.encode(message.normalStyle, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.focusStyle != null && Object.hasOwnProperty.call(message, "focusStyle"))
            $root.Style.encode(message.focusStyle, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.animation != null && Object.hasOwnProperty.call(message, "animation"))
            $root.Animation.encode(message.animation, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.rendered != null && Object.hasOwnProperty.call(message, "rendered"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.rendered);
        if (message.isInitialFocus != null && Object.hasOwnProperty.call(message, "isInitialFocus"))
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isInitialFocus);
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.type);
        if (message.target != null && Object.hasOwnProperty.call(message, "target"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.target);
        if (message.targetType != null && Object.hasOwnProperty.call(message, "targetType"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.targetType);
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.code);
        return writer;
    };

    /**
     * Encodes the specified Button message, length delimited. Does not implicitly {@link Button.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Button
     * @static
     * @param {IButton} message Button message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Button.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Button message from the specified reader or buffer.
     * @function decode
     * @memberof Button
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Button} Button
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Button.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Button();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.normalStyle = $root.Style.decode(reader, reader.uint32());
                break;
            case 2:
                message.focusStyle = $root.Style.decode(reader, reader.uint32());
                break;
            case 3:
                message.animation = $root.Animation.decode(reader, reader.uint32());
                break;
            case 4:
                message.rendered = reader.bool();
                break;
            case 5:
                message.isInitialFocus = reader.bool();
                break;
            case 6:
                message.type = reader.string();
                break;
            case 7:
                message.target = reader.string();
                break;
            case 8:
                message.targetType = reader.string();
                break;
            case 9:
                message.code = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Button message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Button
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Button} Button
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Button.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Button message.
     * @function verify
     * @memberof Button
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Button.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.normalStyle != null && message.hasOwnProperty("normalStyle")) {
            let error = $root.Style.verify(message.normalStyle);
            if (error)
                return "normalStyle." + error;
        }
        if (message.focusStyle != null && message.hasOwnProperty("focusStyle")) {
            let error = $root.Style.verify(message.focusStyle);
            if (error)
                return "focusStyle." + error;
        }
        if (message.animation != null && message.hasOwnProperty("animation")) {
            let error = $root.Animation.verify(message.animation);
            if (error)
                return "animation." + error;
        }
        if (message.rendered != null && message.hasOwnProperty("rendered"))
            if (typeof message.rendered !== "boolean")
                return "rendered: boolean expected";
        if (message.isInitialFocus != null && message.hasOwnProperty("isInitialFocus"))
            if (typeof message.isInitialFocus !== "boolean")
                return "isInitialFocus: boolean expected";
        if (message.type != null && message.hasOwnProperty("type"))
            if (!$util.isString(message.type))
                return "type: string expected";
        if (message.target != null && message.hasOwnProperty("target"))
            if (!$util.isString(message.target))
                return "target: string expected";
        if (message.targetType != null && message.hasOwnProperty("targetType"))
            if (!$util.isString(message.targetType))
                return "targetType: string expected";
        if (message.code != null && message.hasOwnProperty("code"))
            if (!$util.isString(message.code))
                return "code: string expected";
        return null;
    };

    /**
     * Creates a Button message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Button
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Button} Button
     */
    Button.fromObject = function fromObject(object) {
        if (object instanceof $root.Button)
            return object;
        let message = new $root.Button();
        if (object.normalStyle != null) {
            if (typeof object.normalStyle !== "object")
                throw TypeError(".Button.normalStyle: object expected");
            message.normalStyle = $root.Style.fromObject(object.normalStyle);
        }
        if (object.focusStyle != null) {
            if (typeof object.focusStyle !== "object")
                throw TypeError(".Button.focusStyle: object expected");
            message.focusStyle = $root.Style.fromObject(object.focusStyle);
        }
        if (object.animation != null) {
            if (typeof object.animation !== "object")
                throw TypeError(".Button.animation: object expected");
            message.animation = $root.Animation.fromObject(object.animation);
        }
        if (object.rendered != null)
            message.rendered = Boolean(object.rendered);
        if (object.isInitialFocus != null)
            message.isInitialFocus = Boolean(object.isInitialFocus);
        if (object.type != null)
            message.type = String(object.type);
        if (object.target != null)
            message.target = String(object.target);
        if (object.targetType != null)
            message.targetType = String(object.targetType);
        if (object.code != null)
            message.code = String(object.code);
        return message;
    };

    /**
     * Creates a plain object from a Button message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Button
     * @static
     * @param {Button} message Button
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Button.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.normalStyle = null;
            object.focusStyle = null;
            object.animation = null;
            object.rendered = false;
            object.isInitialFocus = false;
            object.type = "";
            object.target = "";
            object.targetType = "";
            object.code = "";
        }
        if (message.normalStyle != null && message.hasOwnProperty("normalStyle"))
            object.normalStyle = $root.Style.toObject(message.normalStyle, options);
        if (message.focusStyle != null && message.hasOwnProperty("focusStyle"))
            object.focusStyle = $root.Style.toObject(message.focusStyle, options);
        if (message.animation != null && message.hasOwnProperty("animation"))
            object.animation = $root.Animation.toObject(message.animation, options);
        if (message.rendered != null && message.hasOwnProperty("rendered"))
            object.rendered = message.rendered;
        if (message.isInitialFocus != null && message.hasOwnProperty("isInitialFocus"))
            object.isInitialFocus = message.isInitialFocus;
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = message.type;
        if (message.target != null && message.hasOwnProperty("target"))
            object.target = message.target;
        if (message.targetType != null && message.hasOwnProperty("targetType"))
            object.targetType = message.targetType;
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = message.code;
        return object;
    };

    /**
     * Converts this Button to JSON.
     * @function toJSON
     * @memberof Button
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Button.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return Button;
})();

export const Style = $root.Style = (() => {

    /**
     * Properties of a Style.
     * @exports IStyle
     * @interface IStyle
     * @property {string|null} [width] Style width
     * @property {string|null} [height] Style height
     * @property {string|null} [top] Style top
     * @property {string|null} [left] Style left
     * @property {string|null} [bottom] Style bottom
     * @property {string|null} [right] Style right
     * @property {string|null} [padding] Style padding
     * @property {string|null} [margin] Style margin
     * @property {string|null} [backgroundImage] Style backgroundImage
     * @property {string|null} [backgroundColor] Style backgroundColor
     * @property {string|null} [fontColor] Style fontColor
     * @property {string|null} [label] Style label
     */

    /**
     * Constructs a new Style.
     * @exports Style
     * @classdesc Represents a Style.
     * @implements IStyle
     * @constructor
     * @param {IStyle=} [properties] Properties to set
     */
    function Style(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Style width.
     * @member {string} width
     * @memberof Style
     * @instance
     */
    Style.prototype.width = "";

    /**
     * Style height.
     * @member {string} height
     * @memberof Style
     * @instance
     */
    Style.prototype.height = "";

    /**
     * Style top.
     * @member {string} top
     * @memberof Style
     * @instance
     */
    Style.prototype.top = "";

    /**
     * Style left.
     * @member {string} left
     * @memberof Style
     * @instance
     */
    Style.prototype.left = "";

    /**
     * Style bottom.
     * @member {string} bottom
     * @memberof Style
     * @instance
     */
    Style.prototype.bottom = "";

    /**
     * Style right.
     * @member {string} right
     * @memberof Style
     * @instance
     */
    Style.prototype.right = "";

    /**
     * Style padding.
     * @member {string} padding
     * @memberof Style
     * @instance
     */
    Style.prototype.padding = "";

    /**
     * Style margin.
     * @member {string} margin
     * @memberof Style
     * @instance
     */
    Style.prototype.margin = "";

    /**
     * Style backgroundImage.
     * @member {string} backgroundImage
     * @memberof Style
     * @instance
     */
    Style.prototype.backgroundImage = "";

    /**
     * Style backgroundColor.
     * @member {string} backgroundColor
     * @memberof Style
     * @instance
     */
    Style.prototype.backgroundColor = "";

    /**
     * Style fontColor.
     * @member {string} fontColor
     * @memberof Style
     * @instance
     */
    Style.prototype.fontColor = "";

    /**
     * Style label.
     * @member {string} label
     * @memberof Style
     * @instance
     */
    Style.prototype.label = "";

    /**
     * Creates a new Style instance using the specified properties.
     * @function create
     * @memberof Style
     * @static
     * @param {IStyle=} [properties] Properties to set
     * @returns {Style} Style instance
     */
    Style.create = function create(properties) {
        return new Style(properties);
    };

    /**
     * Encodes the specified Style message. Does not implicitly {@link Style.verify|verify} messages.
     * @function encode
     * @memberof Style
     * @static
     * @param {IStyle} message Style message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Style.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.width != null && Object.hasOwnProperty.call(message, "width"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.width);
        if (message.height != null && Object.hasOwnProperty.call(message, "height"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.height);
        if (message.top != null && Object.hasOwnProperty.call(message, "top"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.top);
        if (message.left != null && Object.hasOwnProperty.call(message, "left"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.left);
        if (message.bottom != null && Object.hasOwnProperty.call(message, "bottom"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.bottom);
        if (message.right != null && Object.hasOwnProperty.call(message, "right"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.right);
        if (message.padding != null && Object.hasOwnProperty.call(message, "padding"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.padding);
        if (message.margin != null && Object.hasOwnProperty.call(message, "margin"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.margin);
        if (message.backgroundImage != null && Object.hasOwnProperty.call(message, "backgroundImage"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.backgroundImage);
        if (message.backgroundColor != null && Object.hasOwnProperty.call(message, "backgroundColor"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.backgroundColor);
        if (message.fontColor != null && Object.hasOwnProperty.call(message, "fontColor"))
            writer.uint32(/* id 11, wireType 2 =*/90).string(message.fontColor);
        if (message.label != null && Object.hasOwnProperty.call(message, "label"))
            writer.uint32(/* id 12, wireType 2 =*/98).string(message.label);
        return writer;
    };

    /**
     * Encodes the specified Style message, length delimited. Does not implicitly {@link Style.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Style
     * @static
     * @param {IStyle} message Style message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Style.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Style message from the specified reader or buffer.
     * @function decode
     * @memberof Style
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Style} Style
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Style.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Style();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.width = reader.string();
                break;
            case 2:
                message.height = reader.string();
                break;
            case 3:
                message.top = reader.string();
                break;
            case 4:
                message.left = reader.string();
                break;
            case 5:
                message.bottom = reader.string();
                break;
            case 6:
                message.right = reader.string();
                break;
            case 7:
                message.padding = reader.string();
                break;
            case 8:
                message.margin = reader.string();
                break;
            case 9:
                message.backgroundImage = reader.string();
                break;
            case 10:
                message.backgroundColor = reader.string();
                break;
            case 11:
                message.fontColor = reader.string();
                break;
            case 12:
                message.label = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Style message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Style
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Style} Style
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Style.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Style message.
     * @function verify
     * @memberof Style
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Style.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.width != null && message.hasOwnProperty("width"))
            if (!$util.isString(message.width))
                return "width: string expected";
        if (message.height != null && message.hasOwnProperty("height"))
            if (!$util.isString(message.height))
                return "height: string expected";
        if (message.top != null && message.hasOwnProperty("top"))
            if (!$util.isString(message.top))
                return "top: string expected";
        if (message.left != null && message.hasOwnProperty("left"))
            if (!$util.isString(message.left))
                return "left: string expected";
        if (message.bottom != null && message.hasOwnProperty("bottom"))
            if (!$util.isString(message.bottom))
                return "bottom: string expected";
        if (message.right != null && message.hasOwnProperty("right"))
            if (!$util.isString(message.right))
                return "right: string expected";
        if (message.padding != null && message.hasOwnProperty("padding"))
            if (!$util.isString(message.padding))
                return "padding: string expected";
        if (message.margin != null && message.hasOwnProperty("margin"))
            if (!$util.isString(message.margin))
                return "margin: string expected";
        if (message.backgroundImage != null && message.hasOwnProperty("backgroundImage"))
            if (!$util.isString(message.backgroundImage))
                return "backgroundImage: string expected";
        if (message.backgroundColor != null && message.hasOwnProperty("backgroundColor"))
            if (!$util.isString(message.backgroundColor))
                return "backgroundColor: string expected";
        if (message.fontColor != null && message.hasOwnProperty("fontColor"))
            if (!$util.isString(message.fontColor))
                return "fontColor: string expected";
        if (message.label != null && message.hasOwnProperty("label"))
            if (!$util.isString(message.label))
                return "label: string expected";
        return null;
    };

    /**
     * Creates a Style message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Style
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Style} Style
     */
    Style.fromObject = function fromObject(object) {
        if (object instanceof $root.Style)
            return object;
        let message = new $root.Style();
        if (object.width != null)
            message.width = String(object.width);
        if (object.height != null)
            message.height = String(object.height);
        if (object.top != null)
            message.top = String(object.top);
        if (object.left != null)
            message.left = String(object.left);
        if (object.bottom != null)
            message.bottom = String(object.bottom);
        if (object.right != null)
            message.right = String(object.right);
        if (object.padding != null)
            message.padding = String(object.padding);
        if (object.margin != null)
            message.margin = String(object.margin);
        if (object.backgroundImage != null)
            message.backgroundImage = String(object.backgroundImage);
        if (object.backgroundColor != null)
            message.backgroundColor = String(object.backgroundColor);
        if (object.fontColor != null)
            message.fontColor = String(object.fontColor);
        if (object.label != null)
            message.label = String(object.label);
        return message;
    };

    /**
     * Creates a plain object from a Style message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Style
     * @static
     * @param {Style} message Style
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Style.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.width = "";
            object.height = "";
            object.top = "";
            object.left = "";
            object.bottom = "";
            object.right = "";
            object.padding = "";
            object.margin = "";
            object.backgroundImage = "";
            object.backgroundColor = "";
            object.fontColor = "";
            object.label = "";
        }
        if (message.width != null && message.hasOwnProperty("width"))
            object.width = message.width;
        if (message.height != null && message.hasOwnProperty("height"))
            object.height = message.height;
        if (message.top != null && message.hasOwnProperty("top"))
            object.top = message.top;
        if (message.left != null && message.hasOwnProperty("left"))
            object.left = message.left;
        if (message.bottom != null && message.hasOwnProperty("bottom"))
            object.bottom = message.bottom;
        if (message.right != null && message.hasOwnProperty("right"))
            object.right = message.right;
        if (message.padding != null && message.hasOwnProperty("padding"))
            object.padding = message.padding;
        if (message.margin != null && message.hasOwnProperty("margin"))
            object.margin = message.margin;
        if (message.backgroundImage != null && message.hasOwnProperty("backgroundImage"))
            object.backgroundImage = message.backgroundImage;
        if (message.backgroundColor != null && message.hasOwnProperty("backgroundColor"))
            object.backgroundColor = message.backgroundColor;
        if (message.fontColor != null && message.hasOwnProperty("fontColor"))
            object.fontColor = message.fontColor;
        if (message.label != null && message.hasOwnProperty("label"))
            object.label = message.label;
        return object;
    };

    /**
     * Converts this Style to JSON.
     * @function toJSON
     * @memberof Style
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Style.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return Style;
})();

export const ITVEvent = $root.ITVEvent = (() => {

    /**
     * Properties of a ITVEvent.
     * @exports IITVEvent
     * @interface IITVEvent
     * @property {string|null} [id] ITVEvent id
     * @property {string|null} [title] ITVEvent title
     * @property {string|null} [homeAsset] ITVEvent homeAsset
     * @property {Object.<string,google.protobuf.IAny>|null} [assets] ITVEvent assets
     * @property {google.protobuf.ITimestamp|null} [startDate] ITVEvent startDate
     * @property {google.protobuf.ITimestamp|null} [endDate] ITVEvent endDate
     * @property {ITrigger|null} [trigger] ITVEvent trigger
     * @property {number|null} [templateId] ITVEvent templateId
     * @property {string|null} [relaunchType] ITVEvent relaunchType
     * @property {number|null} [version] ITVEvent version
     * @property {string|null} [scheduleStatus] ITVEvent scheduleStatus
     * @property {string|null} [launchType] ITVEvent launchType
     * @property {boolean|null} [invisible] ITVEvent invisible
     * @property {number|null} [offset] ITVEvent offset
     * @property {number|null} [programLength] ITVEvent programLength
     * @property {boolean|null} [showNotificationSettings] ITVEvent showNotificationSettings
     */

    /**
     * Constructs a new ITVEvent.
     * @exports ITVEvent
     * @classdesc Represents a ITVEvent.
     * @implements IITVEvent
     * @constructor
     * @param {IITVEvent=} [properties] Properties to set
     */
    function ITVEvent(properties) {
        this.assets = {};
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ITVEvent id.
     * @member {string} id
     * @memberof ITVEvent
     * @instance
     */
    ITVEvent.prototype.id = "";

    /**
     * ITVEvent title.
     * @member {string} title
     * @memberof ITVEvent
     * @instance
     */
    ITVEvent.prototype.title = "";

    /**
     * ITVEvent homeAsset.
     * @member {string} homeAsset
     * @memberof ITVEvent
     * @instance
     */
    ITVEvent.prototype.homeAsset = "";

    /**
     * ITVEvent assets.
     * @member {Object.<string,google.protobuf.IAny>} assets
     * @memberof ITVEvent
     * @instance
     */
    ITVEvent.prototype.assets = $util.emptyObject;

    /**
     * ITVEvent startDate.
     * @member {google.protobuf.ITimestamp|null|undefined} startDate
     * @memberof ITVEvent
     * @instance
     */
    ITVEvent.prototype.startDate = null;

    /**
     * ITVEvent endDate.
     * @member {google.protobuf.ITimestamp|null|undefined} endDate
     * @memberof ITVEvent
     * @instance
     */
    ITVEvent.prototype.endDate = null;

    /**
     * ITVEvent trigger.
     * @member {ITrigger|null|undefined} trigger
     * @memberof ITVEvent
     * @instance
     */
    ITVEvent.prototype.trigger = null;

    /**
     * ITVEvent templateId.
     * @member {number} templateId
     * @memberof ITVEvent
     * @instance
     */
    ITVEvent.prototype.templateId = 0;

    /**
     * ITVEvent relaunchType.
     * @member {string} relaunchType
     * @memberof ITVEvent
     * @instance
     */
    ITVEvent.prototype.relaunchType = "";

    /**
     * ITVEvent version.
     * @member {number} version
     * @memberof ITVEvent
     * @instance
     */
    ITVEvent.prototype.version = 0;

    /**
     * ITVEvent scheduleStatus.
     * @member {string} scheduleStatus
     * @memberof ITVEvent
     * @instance
     */
    ITVEvent.prototype.scheduleStatus = "";

    /**
     * ITVEvent launchType.
     * @member {string} launchType
     * @memberof ITVEvent
     * @instance
     */
    ITVEvent.prototype.launchType = "";

    /**
     * ITVEvent invisible.
     * @member {boolean} invisible
     * @memberof ITVEvent
     * @instance
     */
    ITVEvent.prototype.invisible = false;

    /**
     * ITVEvent offset.
     * @member {number} offset
     * @memberof ITVEvent
     * @instance
     */
    ITVEvent.prototype.offset = 0;

    /**
     * ITVEvent programLength.
     * @member {number} programLength
     * @memberof ITVEvent
     * @instance
     */
    ITVEvent.prototype.programLength = 0;

    /**
     * ITVEvent showNotificationSettings.
     * @member {boolean} showNotificationSettings
     * @memberof ITVEvent
     * @instance
     */
    ITVEvent.prototype.showNotificationSettings = false;

    /**
     * Creates a new ITVEvent instance using the specified properties.
     * @function create
     * @memberof ITVEvent
     * @static
     * @param {IITVEvent=} [properties] Properties to set
     * @returns {ITVEvent} ITVEvent instance
     */
    ITVEvent.create = function create(properties) {
        return new ITVEvent(properties);
    };

    /**
     * Encodes the specified ITVEvent message. Does not implicitly {@link ITVEvent.verify|verify} messages.
     * @function encode
     * @memberof ITVEvent
     * @static
     * @param {IITVEvent} message ITVEvent message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ITVEvent.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        if (message.title != null && Object.hasOwnProperty.call(message, "title"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
        if (message.homeAsset != null && Object.hasOwnProperty.call(message, "homeAsset"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.homeAsset);
        if (message.assets != null && Object.hasOwnProperty.call(message, "assets"))
            for (let keys = Object.keys(message.assets), i = 0; i < keys.length; ++i) {
                writer.uint32(/* id 4, wireType 2 =*/34).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                $root.google.protobuf.Any.encode(message.assets[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
            }
        if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
            $root.google.protobuf.Timestamp.encode(message.startDate, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        if (message.endDate != null && Object.hasOwnProperty.call(message, "endDate"))
            $root.google.protobuf.Timestamp.encode(message.endDate, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        if (message.trigger != null && Object.hasOwnProperty.call(message, "trigger"))
            $root.Trigger.encode(message.trigger, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.templateId != null && Object.hasOwnProperty.call(message, "templateId"))
            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.templateId);
        if (message.relaunchType != null && Object.hasOwnProperty.call(message, "relaunchType"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.relaunchType);
        if (message.version != null && Object.hasOwnProperty.call(message, "version"))
            writer.uint32(/* id 10, wireType 0 =*/80).int32(message.version);
        if (message.scheduleStatus != null && Object.hasOwnProperty.call(message, "scheduleStatus"))
            writer.uint32(/* id 11, wireType 2 =*/90).string(message.scheduleStatus);
        if (message.launchType != null && Object.hasOwnProperty.call(message, "launchType"))
            writer.uint32(/* id 12, wireType 2 =*/98).string(message.launchType);
        if (message.invisible != null && Object.hasOwnProperty.call(message, "invisible"))
            writer.uint32(/* id 13, wireType 0 =*/104).bool(message.invisible);
        if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
            writer.uint32(/* id 14, wireType 0 =*/112).int32(message.offset);
        if (message.programLength != null && Object.hasOwnProperty.call(message, "programLength"))
            writer.uint32(/* id 15, wireType 0 =*/120).int32(message.programLength);
        if (message.showNotificationSettings != null && Object.hasOwnProperty.call(message, "showNotificationSettings"))
            writer.uint32(/* id 16, wireType 0 =*/128).bool(message.showNotificationSettings);
        return writer;
    };

    /**
     * Encodes the specified ITVEvent message, length delimited. Does not implicitly {@link ITVEvent.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ITVEvent
     * @static
     * @param {IITVEvent} message ITVEvent message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ITVEvent.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ITVEvent message from the specified reader or buffer.
     * @function decode
     * @memberof ITVEvent
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ITVEvent} ITVEvent
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ITVEvent.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ITVEvent(), key, value;
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.id = reader.string();
                break;
            case 2:
                message.title = reader.string();
                break;
            case 3:
                message.homeAsset = reader.string();
                break;
            case 4:
                if (message.assets === $util.emptyObject)
                    message.assets = {};
                let end2 = reader.uint32() + reader.pos;
                key = "";
                value = null;
                while (reader.pos < end2) {
                    let tag2 = reader.uint32();
                    switch (tag2 >>> 3) {
                    case 1:
                        key = reader.string();
                        break;
                    case 2:
                        value = $root.google.protobuf.Any.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag2 & 7);
                        break;
                    }
                }
                message.assets[key] = value;
                break;
            case 5:
                message.startDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                break;
            case 6:
                message.endDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                break;
            case 7:
                message.trigger = $root.Trigger.decode(reader, reader.uint32());
                break;
            case 8:
                message.templateId = reader.int32();
                break;
            case 9:
                message.relaunchType = reader.string();
                break;
            case 10:
                message.version = reader.int32();
                break;
            case 11:
                message.scheduleStatus = reader.string();
                break;
            case 12:
                message.launchType = reader.string();
                break;
            case 13:
                message.invisible = reader.bool();
                break;
            case 14:
                message.offset = reader.int32();
                break;
            case 15:
                message.programLength = reader.int32();
                break;
            case 16:
                message.showNotificationSettings = reader.bool();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ITVEvent message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ITVEvent
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ITVEvent} ITVEvent
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ITVEvent.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ITVEvent message.
     * @function verify
     * @memberof ITVEvent
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ITVEvent.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.title != null && message.hasOwnProperty("title"))
            if (!$util.isString(message.title))
                return "title: string expected";
        if (message.homeAsset != null && message.hasOwnProperty("homeAsset"))
            if (!$util.isString(message.homeAsset))
                return "homeAsset: string expected";
        if (message.assets != null && message.hasOwnProperty("assets")) {
            if (!$util.isObject(message.assets))
                return "assets: object expected";
            let key = Object.keys(message.assets);
            for (let i = 0; i < key.length; ++i) {
                let error = $root.google.protobuf.Any.verify(message.assets[key[i]]);
                if (error)
                    return "assets." + error;
            }
        }
        if (message.startDate != null && message.hasOwnProperty("startDate")) {
            let error = $root.google.protobuf.Timestamp.verify(message.startDate);
            if (error)
                return "startDate." + error;
        }
        if (message.endDate != null && message.hasOwnProperty("endDate")) {
            let error = $root.google.protobuf.Timestamp.verify(message.endDate);
            if (error)
                return "endDate." + error;
        }
        if (message.trigger != null && message.hasOwnProperty("trigger")) {
            let error = $root.Trigger.verify(message.trigger);
            if (error)
                return "trigger." + error;
        }
        if (message.templateId != null && message.hasOwnProperty("templateId"))
            if (!$util.isInteger(message.templateId))
                return "templateId: integer expected";
        if (message.relaunchType != null && message.hasOwnProperty("relaunchType"))
            if (!$util.isString(message.relaunchType))
                return "relaunchType: string expected";
        if (message.version != null && message.hasOwnProperty("version"))
            if (!$util.isInteger(message.version))
                return "version: integer expected";
        if (message.scheduleStatus != null && message.hasOwnProperty("scheduleStatus"))
            if (!$util.isString(message.scheduleStatus))
                return "scheduleStatus: string expected";
        if (message.launchType != null && message.hasOwnProperty("launchType"))
            if (!$util.isString(message.launchType))
                return "launchType: string expected";
        if (message.invisible != null && message.hasOwnProperty("invisible"))
            if (typeof message.invisible !== "boolean")
                return "invisible: boolean expected";
        if (message.offset != null && message.hasOwnProperty("offset"))
            if (!$util.isInteger(message.offset))
                return "offset: integer expected";
        if (message.programLength != null && message.hasOwnProperty("programLength"))
            if (!$util.isInteger(message.programLength))
                return "programLength: integer expected";
        if (message.showNotificationSettings != null && message.hasOwnProperty("showNotificationSettings"))
            if (typeof message.showNotificationSettings !== "boolean")
                return "showNotificationSettings: boolean expected";
        return null;
    };

    /**
     * Creates a ITVEvent message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ITVEvent
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ITVEvent} ITVEvent
     */
    ITVEvent.fromObject = function fromObject(object) {
        if (object instanceof $root.ITVEvent)
            return object;
        let message = new $root.ITVEvent();
        if (object.id != null)
            message.id = String(object.id);
        if (object.title != null)
            message.title = String(object.title);
        if (object.homeAsset != null)
            message.homeAsset = String(object.homeAsset);
        if (object.assets) {
            if (typeof object.assets !== "object")
                throw TypeError(".ITVEvent.assets: object expected");
            message.assets = {};
            for (let keys = Object.keys(object.assets), i = 0; i < keys.length; ++i) {
                if (typeof object.assets[keys[i]] !== "object")
                    throw TypeError(".ITVEvent.assets: object expected");
                message.assets[keys[i]] = $root.google.protobuf.Any.fromObject(object.assets[keys[i]]);
            }
        }
        if (object.startDate != null) {
            if (typeof object.startDate !== "object")
                throw TypeError(".ITVEvent.startDate: object expected");
            message.startDate = $root.google.protobuf.Timestamp.fromObject(object.startDate);
        }
        if (object.endDate != null) {
            if (typeof object.endDate !== "object")
                throw TypeError(".ITVEvent.endDate: object expected");
            message.endDate = $root.google.protobuf.Timestamp.fromObject(object.endDate);
        }
        if (object.trigger != null) {
            if (typeof object.trigger !== "object")
                throw TypeError(".ITVEvent.trigger: object expected");
            message.trigger = $root.Trigger.fromObject(object.trigger);
        }
        if (object.templateId != null)
            message.templateId = object.templateId | 0;
        if (object.relaunchType != null)
            message.relaunchType = String(object.relaunchType);
        if (object.version != null)
            message.version = object.version | 0;
        if (object.scheduleStatus != null)
            message.scheduleStatus = String(object.scheduleStatus);
        if (object.launchType != null)
            message.launchType = String(object.launchType);
        if (object.invisible != null)
            message.invisible = Boolean(object.invisible);
        if (object.offset != null)
            message.offset = object.offset | 0;
        if (object.programLength != null)
            message.programLength = object.programLength | 0;
        if (object.showNotificationSettings != null)
            message.showNotificationSettings = Boolean(object.showNotificationSettings);
        return message;
    };

    /**
     * Creates a plain object from a ITVEvent message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ITVEvent
     * @static
     * @param {ITVEvent} message ITVEvent
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ITVEvent.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.objects || options.defaults)
            object.assets = {};
        if (options.defaults) {
            object.id = "";
            object.title = "";
            object.homeAsset = "";
            object.startDate = null;
            object.endDate = null;
            object.trigger = null;
            object.templateId = 0;
            object.relaunchType = "";
            object.version = 0;
            object.scheduleStatus = "";
            object.launchType = "";
            object.invisible = false;
            object.offset = 0;
            object.programLength = 0;
            object.showNotificationSettings = false;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.title != null && message.hasOwnProperty("title"))
            object.title = message.title;
        if (message.homeAsset != null && message.hasOwnProperty("homeAsset"))
            object.homeAsset = message.homeAsset;
        let keys2;
        if (message.assets && (keys2 = Object.keys(message.assets)).length) {
            object.assets = {};
            for (let j = 0; j < keys2.length; ++j)
                object.assets[keys2[j]] = $root.google.protobuf.Any.toObject(message.assets[keys2[j]], options);
        }
        if (message.startDate != null && message.hasOwnProperty("startDate"))
            object.startDate = $root.google.protobuf.Timestamp.toObject(message.startDate, options);
        if (message.endDate != null && message.hasOwnProperty("endDate"))
            object.endDate = $root.google.protobuf.Timestamp.toObject(message.endDate, options);
        if (message.trigger != null && message.hasOwnProperty("trigger"))
            object.trigger = $root.Trigger.toObject(message.trigger, options);
        if (message.templateId != null && message.hasOwnProperty("templateId"))
            object.templateId = message.templateId;
        if (message.relaunchType != null && message.hasOwnProperty("relaunchType"))
            object.relaunchType = message.relaunchType;
        if (message.version != null && message.hasOwnProperty("version"))
            object.version = message.version;
        if (message.scheduleStatus != null && message.hasOwnProperty("scheduleStatus"))
            object.scheduleStatus = message.scheduleStatus;
        if (message.launchType != null && message.hasOwnProperty("launchType"))
            object.launchType = message.launchType;
        if (message.invisible != null && message.hasOwnProperty("invisible"))
            object.invisible = message.invisible;
        if (message.offset != null && message.hasOwnProperty("offset"))
            object.offset = message.offset;
        if (message.programLength != null && message.hasOwnProperty("programLength"))
            object.programLength = message.programLength;
        if (message.showNotificationSettings != null && message.hasOwnProperty("showNotificationSettings"))
            object.showNotificationSettings = message.showNotificationSettings;
        return object;
    };

    /**
     * Converts this ITVEvent to JSON.
     * @function toJSON
     * @memberof ITVEvent
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ITVEvent.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ITVEvent;
})();

export const ModalContent = $root.ModalContent = (() => {

    /**
     * Properties of a ModalContent.
     * @exports IModalContent
     * @interface IModalContent
     * @property {string|null} [title] ModalContent title
     * @property {string|null} [headline] ModalContent headline
     * @property {string|null} [text] ModalContent text
     * @property {string|null} [backgroundImage] ModalContent backgroundImage
     * @property {boolean|null} [rendered] ModalContent rendered
     */

    /**
     * Constructs a new ModalContent.
     * @exports ModalContent
     * @classdesc Represents a ModalContent.
     * @implements IModalContent
     * @constructor
     * @param {IModalContent=} [properties] Properties to set
     */
    function ModalContent(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ModalContent title.
     * @member {string} title
     * @memberof ModalContent
     * @instance
     */
    ModalContent.prototype.title = "";

    /**
     * ModalContent headline.
     * @member {string} headline
     * @memberof ModalContent
     * @instance
     */
    ModalContent.prototype.headline = "";

    /**
     * ModalContent text.
     * @member {string} text
     * @memberof ModalContent
     * @instance
     */
    ModalContent.prototype.text = "";

    /**
     * ModalContent backgroundImage.
     * @member {string} backgroundImage
     * @memberof ModalContent
     * @instance
     */
    ModalContent.prototype.backgroundImage = "";

    /**
     * ModalContent rendered.
     * @member {boolean} rendered
     * @memberof ModalContent
     * @instance
     */
    ModalContent.prototype.rendered = false;

    /**
     * Creates a new ModalContent instance using the specified properties.
     * @function create
     * @memberof ModalContent
     * @static
     * @param {IModalContent=} [properties] Properties to set
     * @returns {ModalContent} ModalContent instance
     */
    ModalContent.create = function create(properties) {
        return new ModalContent(properties);
    };

    /**
     * Encodes the specified ModalContent message. Does not implicitly {@link ModalContent.verify|verify} messages.
     * @function encode
     * @memberof ModalContent
     * @static
     * @param {IModalContent} message ModalContent message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ModalContent.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.title != null && Object.hasOwnProperty.call(message, "title"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.title);
        if (message.headline != null && Object.hasOwnProperty.call(message, "headline"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.headline);
        if (message.text != null && Object.hasOwnProperty.call(message, "text"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.text);
        if (message.backgroundImage != null && Object.hasOwnProperty.call(message, "backgroundImage"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.backgroundImage);
        if (message.rendered != null && Object.hasOwnProperty.call(message, "rendered"))
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.rendered);
        return writer;
    };

    /**
     * Encodes the specified ModalContent message, length delimited. Does not implicitly {@link ModalContent.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ModalContent
     * @static
     * @param {IModalContent} message ModalContent message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ModalContent.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ModalContent message from the specified reader or buffer.
     * @function decode
     * @memberof ModalContent
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ModalContent} ModalContent
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ModalContent.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ModalContent();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.title = reader.string();
                break;
            case 2:
                message.headline = reader.string();
                break;
            case 3:
                message.text = reader.string();
                break;
            case 4:
                message.backgroundImage = reader.string();
                break;
            case 5:
                message.rendered = reader.bool();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ModalContent message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ModalContent
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ModalContent} ModalContent
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ModalContent.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ModalContent message.
     * @function verify
     * @memberof ModalContent
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ModalContent.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.title != null && message.hasOwnProperty("title"))
            if (!$util.isString(message.title))
                return "title: string expected";
        if (message.headline != null && message.hasOwnProperty("headline"))
            if (!$util.isString(message.headline))
                return "headline: string expected";
        if (message.text != null && message.hasOwnProperty("text"))
            if (!$util.isString(message.text))
                return "text: string expected";
        if (message.backgroundImage != null && message.hasOwnProperty("backgroundImage"))
            if (!$util.isString(message.backgroundImage))
                return "backgroundImage: string expected";
        if (message.rendered != null && message.hasOwnProperty("rendered"))
            if (typeof message.rendered !== "boolean")
                return "rendered: boolean expected";
        return null;
    };

    /**
     * Creates a ModalContent message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ModalContent
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ModalContent} ModalContent
     */
    ModalContent.fromObject = function fromObject(object) {
        if (object instanceof $root.ModalContent)
            return object;
        let message = new $root.ModalContent();
        if (object.title != null)
            message.title = String(object.title);
        if (object.headline != null)
            message.headline = String(object.headline);
        if (object.text != null)
            message.text = String(object.text);
        if (object.backgroundImage != null)
            message.backgroundImage = String(object.backgroundImage);
        if (object.rendered != null)
            message.rendered = Boolean(object.rendered);
        return message;
    };

    /**
     * Creates a plain object from a ModalContent message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ModalContent
     * @static
     * @param {ModalContent} message ModalContent
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ModalContent.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.title = "";
            object.headline = "";
            object.text = "";
            object.backgroundImage = "";
            object.rendered = false;
        }
        if (message.title != null && message.hasOwnProperty("title"))
            object.title = message.title;
        if (message.headline != null && message.hasOwnProperty("headline"))
            object.headline = message.headline;
        if (message.text != null && message.hasOwnProperty("text"))
            object.text = message.text;
        if (message.backgroundImage != null && message.hasOwnProperty("backgroundImage"))
            object.backgroundImage = message.backgroundImage;
        if (message.rendered != null && message.hasOwnProperty("rendered"))
            object.rendered = message.rendered;
        return object;
    };

    /**
     * Converts this ModalContent to JSON.
     * @function toJSON
     * @memberof ModalContent
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ModalContent.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ModalContent;
})();

export const Redirect = $root.Redirect = (() => {

    /**
     * Properties of a Redirect.
     * @exports IRedirect
     * @interface IRedirect
     * @property {string|null} [redirectId] Redirect redirectId
     * @property {string|null} [eventId] Redirect eventId
     * @property {string|null} [url] Redirect url
     */

    /**
     * Constructs a new Redirect.
     * @exports Redirect
     * @classdesc Represents a Redirect.
     * @implements IRedirect
     * @constructor
     * @param {IRedirect=} [properties] Properties to set
     */
    function Redirect(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Redirect redirectId.
     * @member {string} redirectId
     * @memberof Redirect
     * @instance
     */
    Redirect.prototype.redirectId = "";

    /**
     * Redirect eventId.
     * @member {string} eventId
     * @memberof Redirect
     * @instance
     */
    Redirect.prototype.eventId = "";

    /**
     * Redirect url.
     * @member {string} url
     * @memberof Redirect
     * @instance
     */
    Redirect.prototype.url = "";

    /**
     * Creates a new Redirect instance using the specified properties.
     * @function create
     * @memberof Redirect
     * @static
     * @param {IRedirect=} [properties] Properties to set
     * @returns {Redirect} Redirect instance
     */
    Redirect.create = function create(properties) {
        return new Redirect(properties);
    };

    /**
     * Encodes the specified Redirect message. Does not implicitly {@link Redirect.verify|verify} messages.
     * @function encode
     * @memberof Redirect
     * @static
     * @param {IRedirect} message Redirect message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Redirect.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.redirectId != null && Object.hasOwnProperty.call(message, "redirectId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.redirectId);
        if (message.eventId != null && Object.hasOwnProperty.call(message, "eventId"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.eventId);
        if (message.url != null && Object.hasOwnProperty.call(message, "url"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.url);
        return writer;
    };

    /**
     * Encodes the specified Redirect message, length delimited. Does not implicitly {@link Redirect.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Redirect
     * @static
     * @param {IRedirect} message Redirect message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Redirect.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Redirect message from the specified reader or buffer.
     * @function decode
     * @memberof Redirect
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Redirect} Redirect
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Redirect.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Redirect();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.redirectId = reader.string();
                break;
            case 2:
                message.eventId = reader.string();
                break;
            case 3:
                message.url = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Redirect message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Redirect
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Redirect} Redirect
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Redirect.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Redirect message.
     * @function verify
     * @memberof Redirect
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Redirect.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.redirectId != null && message.hasOwnProperty("redirectId"))
            if (!$util.isString(message.redirectId))
                return "redirectId: string expected";
        if (message.eventId != null && message.hasOwnProperty("eventId"))
            if (!$util.isString(message.eventId))
                return "eventId: string expected";
        if (message.url != null && message.hasOwnProperty("url"))
            if (!$util.isString(message.url))
                return "url: string expected";
        return null;
    };

    /**
     * Creates a Redirect message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Redirect
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Redirect} Redirect
     */
    Redirect.fromObject = function fromObject(object) {
        if (object instanceof $root.Redirect)
            return object;
        let message = new $root.Redirect();
        if (object.redirectId != null)
            message.redirectId = String(object.redirectId);
        if (object.eventId != null)
            message.eventId = String(object.eventId);
        if (object.url != null)
            message.url = String(object.url);
        return message;
    };

    /**
     * Creates a plain object from a Redirect message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Redirect
     * @static
     * @param {Redirect} message Redirect
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Redirect.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.redirectId = "";
            object.eventId = "";
            object.url = "";
        }
        if (message.redirectId != null && message.hasOwnProperty("redirectId"))
            object.redirectId = message.redirectId;
        if (message.eventId != null && message.hasOwnProperty("eventId"))
            object.eventId = message.eventId;
        if (message.url != null && message.hasOwnProperty("url"))
            object.url = message.url;
        return object;
    };

    /**
     * Converts this Redirect to JSON.
     * @function toJSON
     * @memberof Redirect
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Redirect.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return Redirect;
})();

export const Snipe = $root.Snipe = (() => {

    /**
     * Properties of a Snipe.
     * @exports ISnipe
     * @interface ISnipe
     * @property {string|null} [snipeId] Snipe snipeId
     * @property {number|null} [templateId] Snipe templateId
     * @property {IStyle|null} [style] Snipe style
     * @property {Array.<IButton>|null} [buttons] Snipe buttons
     * @property {IAnimation|null} [animation] Snipe animation
     * @property {string|null} [visible] Snipe visible
     * @property {number|null} [template] Snipe template
     * @property {string|null} [topText] Snipe topText
     * @property {string|null} [bottomText] Snipe bottomText
     * @property {string|null} [appIcon] Snipe appIcon
     */

    /**
     * Constructs a new Snipe.
     * @exports Snipe
     * @classdesc Represents a Snipe.
     * @implements ISnipe
     * @constructor
     * @param {ISnipe=} [properties] Properties to set
     */
    function Snipe(properties) {
        this.buttons = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Snipe snipeId.
     * @member {string} snipeId
     * @memberof Snipe
     * @instance
     */
    Snipe.prototype.snipeId = "";

    /**
     * Snipe templateId.
     * @member {number} templateId
     * @memberof Snipe
     * @instance
     */
    Snipe.prototype.templateId = 0;

    /**
     * Snipe style.
     * @member {IStyle|null|undefined} style
     * @memberof Snipe
     * @instance
     */
    Snipe.prototype.style = null;

    /**
     * Snipe buttons.
     * @member {Array.<IButton>} buttons
     * @memberof Snipe
     * @instance
     */
    Snipe.prototype.buttons = $util.emptyArray;

    /**
     * Snipe animation.
     * @member {IAnimation|null|undefined} animation
     * @memberof Snipe
     * @instance
     */
    Snipe.prototype.animation = null;

    /**
     * Snipe visible.
     * @member {string} visible
     * @memberof Snipe
     * @instance
     */
    Snipe.prototype.visible = "";

    /**
     * Snipe template.
     * @member {number} template
     * @memberof Snipe
     * @instance
     */
    Snipe.prototype.template = 0;

    /**
     * Snipe topText.
     * @member {string} topText
     * @memberof Snipe
     * @instance
     */
    Snipe.prototype.topText = "";

    /**
     * Snipe bottomText.
     * @member {string} bottomText
     * @memberof Snipe
     * @instance
     */
    Snipe.prototype.bottomText = "";

    /**
     * Snipe appIcon.
     * @member {string} appIcon
     * @memberof Snipe
     * @instance
     */
    Snipe.prototype.appIcon = "";

    /**
     * Creates a new Snipe instance using the specified properties.
     * @function create
     * @memberof Snipe
     * @static
     * @param {ISnipe=} [properties] Properties to set
     * @returns {Snipe} Snipe instance
     */
    Snipe.create = function create(properties) {
        return new Snipe(properties);
    };

    /**
     * Encodes the specified Snipe message. Does not implicitly {@link Snipe.verify|verify} messages.
     * @function encode
     * @memberof Snipe
     * @static
     * @param {ISnipe} message Snipe message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Snipe.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.snipeId != null && Object.hasOwnProperty.call(message, "snipeId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.snipeId);
        if (message.templateId != null && Object.hasOwnProperty.call(message, "templateId"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.templateId);
        if (message.style != null && Object.hasOwnProperty.call(message, "style"))
            $root.Style.encode(message.style, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.buttons != null && message.buttons.length)
            for (let i = 0; i < message.buttons.length; ++i)
                $root.Button.encode(message.buttons[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.animation != null && Object.hasOwnProperty.call(message, "animation"))
            $root.Animation.encode(message.animation, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        if (message.visible != null && Object.hasOwnProperty.call(message, "visible"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.visible);
        if (message.template != null && Object.hasOwnProperty.call(message, "template"))
            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.template);
        if (message.topText != null && Object.hasOwnProperty.call(message, "topText"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.topText);
        if (message.bottomText != null && Object.hasOwnProperty.call(message, "bottomText"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.bottomText);
        if (message.appIcon != null && Object.hasOwnProperty.call(message, "appIcon"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.appIcon);
        return writer;
    };

    /**
     * Encodes the specified Snipe message, length delimited. Does not implicitly {@link Snipe.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Snipe
     * @static
     * @param {ISnipe} message Snipe message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Snipe.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Snipe message from the specified reader or buffer.
     * @function decode
     * @memberof Snipe
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Snipe} Snipe
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Snipe.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Snipe();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.snipeId = reader.string();
                break;
            case 2:
                message.templateId = reader.int32();
                break;
            case 3:
                message.style = $root.Style.decode(reader, reader.uint32());
                break;
            case 4:
                if (!(message.buttons && message.buttons.length))
                    message.buttons = [];
                message.buttons.push($root.Button.decode(reader, reader.uint32()));
                break;
            case 5:
                message.animation = $root.Animation.decode(reader, reader.uint32());
                break;
            case 6:
                message.visible = reader.string();
                break;
            case 7:
                message.template = reader.int32();
                break;
            case 8:
                message.topText = reader.string();
                break;
            case 9:
                message.bottomText = reader.string();
                break;
            case 10:
                message.appIcon = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Snipe message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Snipe
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Snipe} Snipe
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Snipe.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Snipe message.
     * @function verify
     * @memberof Snipe
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Snipe.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.snipeId != null && message.hasOwnProperty("snipeId"))
            if (!$util.isString(message.snipeId))
                return "snipeId: string expected";
        if (message.templateId != null && message.hasOwnProperty("templateId"))
            if (!$util.isInteger(message.templateId))
                return "templateId: integer expected";
        if (message.style != null && message.hasOwnProperty("style")) {
            let error = $root.Style.verify(message.style);
            if (error)
                return "style." + error;
        }
        if (message.buttons != null && message.hasOwnProperty("buttons")) {
            if (!Array.isArray(message.buttons))
                return "buttons: array expected";
            for (let i = 0; i < message.buttons.length; ++i) {
                let error = $root.Button.verify(message.buttons[i]);
                if (error)
                    return "buttons." + error;
            }
        }
        if (message.animation != null && message.hasOwnProperty("animation")) {
            let error = $root.Animation.verify(message.animation);
            if (error)
                return "animation." + error;
        }
        if (message.visible != null && message.hasOwnProperty("visible"))
            if (!$util.isString(message.visible))
                return "visible: string expected";
        if (message.template != null && message.hasOwnProperty("template"))
            if (!$util.isInteger(message.template))
                return "template: integer expected";
        if (message.topText != null && message.hasOwnProperty("topText"))
            if (!$util.isString(message.topText))
                return "topText: string expected";
        if (message.bottomText != null && message.hasOwnProperty("bottomText"))
            if (!$util.isString(message.bottomText))
                return "bottomText: string expected";
        if (message.appIcon != null && message.hasOwnProperty("appIcon"))
            if (!$util.isString(message.appIcon))
                return "appIcon: string expected";
        return null;
    };

    /**
     * Creates a Snipe message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Snipe
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Snipe} Snipe
     */
    Snipe.fromObject = function fromObject(object) {
        if (object instanceof $root.Snipe)
            return object;
        let message = new $root.Snipe();
        if (object.snipeId != null)
            message.snipeId = String(object.snipeId);
        if (object.templateId != null)
            message.templateId = object.templateId | 0;
        if (object.style != null) {
            if (typeof object.style !== "object")
                throw TypeError(".Snipe.style: object expected");
            message.style = $root.Style.fromObject(object.style);
        }
        if (object.buttons) {
            if (!Array.isArray(object.buttons))
                throw TypeError(".Snipe.buttons: array expected");
            message.buttons = [];
            for (let i = 0; i < object.buttons.length; ++i) {
                if (typeof object.buttons[i] !== "object")
                    throw TypeError(".Snipe.buttons: object expected");
                message.buttons[i] = $root.Button.fromObject(object.buttons[i]);
            }
        }
        if (object.animation != null) {
            if (typeof object.animation !== "object")
                throw TypeError(".Snipe.animation: object expected");
            message.animation = $root.Animation.fromObject(object.animation);
        }
        if (object.visible != null)
            message.visible = String(object.visible);
        if (object.template != null)
            message.template = object.template | 0;
        if (object.topText != null)
            message.topText = String(object.topText);
        if (object.bottomText != null)
            message.bottomText = String(object.bottomText);
        if (object.appIcon != null)
            message.appIcon = String(object.appIcon);
        return message;
    };

    /**
     * Creates a plain object from a Snipe message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Snipe
     * @static
     * @param {Snipe} message Snipe
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Snipe.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.buttons = [];
        if (options.defaults) {
            object.snipeId = "";
            object.templateId = 0;
            object.style = null;
            object.animation = null;
            object.visible = "";
            object.template = 0;
            object.topText = "";
            object.bottomText = "";
            object.appIcon = "";
        }
        if (message.snipeId != null && message.hasOwnProperty("snipeId"))
            object.snipeId = message.snipeId;
        if (message.templateId != null && message.hasOwnProperty("templateId"))
            object.templateId = message.templateId;
        if (message.style != null && message.hasOwnProperty("style"))
            object.style = $root.Style.toObject(message.style, options);
        if (message.buttons && message.buttons.length) {
            object.buttons = [];
            for (let j = 0; j < message.buttons.length; ++j)
                object.buttons[j] = $root.Button.toObject(message.buttons[j], options);
        }
        if (message.animation != null && message.hasOwnProperty("animation"))
            object.animation = $root.Animation.toObject(message.animation, options);
        if (message.visible != null && message.hasOwnProperty("visible"))
            object.visible = message.visible;
        if (message.template != null && message.hasOwnProperty("template"))
            object.template = message.template;
        if (message.topText != null && message.hasOwnProperty("topText"))
            object.topText = message.topText;
        if (message.bottomText != null && message.hasOwnProperty("bottomText"))
            object.bottomText = message.bottomText;
        if (message.appIcon != null && message.hasOwnProperty("appIcon"))
            object.appIcon = message.appIcon;
        return object;
    };

    /**
     * Converts this Snipe to JSON.
     * @function toJSON
     * @memberof Snipe
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Snipe.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return Snipe;
})();

export const TemplateApp = $root.TemplateApp = (() => {

    /**
     * Properties of a TemplateApp.
     * @exports ITemplateApp
     * @interface ITemplateApp
     * @property {string|null} [visible] TemplateApp visible
     * @property {string|null} [templateAppId] TemplateApp templateAppId
     * @property {number|null} [templateId] TemplateApp templateId
     * @property {IStyle|null} [style] TemplateApp style
     * @property {Array.<IButton>|null} [buttons] TemplateApp buttons
     * @property {IModalContent|null} [modalContent] TemplateApp modalContent
     * @property {string|null} [urlForward] TemplateApp urlForward
     */

    /**
     * Constructs a new TemplateApp.
     * @exports TemplateApp
     * @classdesc Represents a TemplateApp.
     * @implements ITemplateApp
     * @constructor
     * @param {ITemplateApp=} [properties] Properties to set
     */
    function TemplateApp(properties) {
        this.buttons = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * TemplateApp visible.
     * @member {string} visible
     * @memberof TemplateApp
     * @instance
     */
    TemplateApp.prototype.visible = "";

    /**
     * TemplateApp templateAppId.
     * @member {string} templateAppId
     * @memberof TemplateApp
     * @instance
     */
    TemplateApp.prototype.templateAppId = "";

    /**
     * TemplateApp templateId.
     * @member {number} templateId
     * @memberof TemplateApp
     * @instance
     */
    TemplateApp.prototype.templateId = 0;

    /**
     * TemplateApp style.
     * @member {IStyle|null|undefined} style
     * @memberof TemplateApp
     * @instance
     */
    TemplateApp.prototype.style = null;

    /**
     * TemplateApp buttons.
     * @member {Array.<IButton>} buttons
     * @memberof TemplateApp
     * @instance
     */
    TemplateApp.prototype.buttons = $util.emptyArray;

    /**
     * TemplateApp modalContent.
     * @member {IModalContent|null|undefined} modalContent
     * @memberof TemplateApp
     * @instance
     */
    TemplateApp.prototype.modalContent = null;

    /**
     * TemplateApp urlForward.
     * @member {string} urlForward
     * @memberof TemplateApp
     * @instance
     */
    TemplateApp.prototype.urlForward = "";

    /**
     * Creates a new TemplateApp instance using the specified properties.
     * @function create
     * @memberof TemplateApp
     * @static
     * @param {ITemplateApp=} [properties] Properties to set
     * @returns {TemplateApp} TemplateApp instance
     */
    TemplateApp.create = function create(properties) {
        return new TemplateApp(properties);
    };

    /**
     * Encodes the specified TemplateApp message. Does not implicitly {@link TemplateApp.verify|verify} messages.
     * @function encode
     * @memberof TemplateApp
     * @static
     * @param {ITemplateApp} message TemplateApp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TemplateApp.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.visible != null && Object.hasOwnProperty.call(message, "visible"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.visible);
        if (message.templateAppId != null && Object.hasOwnProperty.call(message, "templateAppId"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.templateAppId);
        if (message.templateId != null && Object.hasOwnProperty.call(message, "templateId"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.templateId);
        if (message.style != null && Object.hasOwnProperty.call(message, "style"))
            $root.Style.encode(message.style, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.buttons != null && message.buttons.length)
            for (let i = 0; i < message.buttons.length; ++i)
                $root.Button.encode(message.buttons[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        if (message.modalContent != null && Object.hasOwnProperty.call(message, "modalContent"))
            $root.ModalContent.encode(message.modalContent, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        if (message.urlForward != null && Object.hasOwnProperty.call(message, "urlForward"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.urlForward);
        return writer;
    };

    /**
     * Encodes the specified TemplateApp message, length delimited. Does not implicitly {@link TemplateApp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof TemplateApp
     * @static
     * @param {ITemplateApp} message TemplateApp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TemplateApp.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a TemplateApp message from the specified reader or buffer.
     * @function decode
     * @memberof TemplateApp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {TemplateApp} TemplateApp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TemplateApp.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TemplateApp();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.visible = reader.string();
                break;
            case 2:
                message.templateAppId = reader.string();
                break;
            case 3:
                message.templateId = reader.int32();
                break;
            case 4:
                message.style = $root.Style.decode(reader, reader.uint32());
                break;
            case 5:
                if (!(message.buttons && message.buttons.length))
                    message.buttons = [];
                message.buttons.push($root.Button.decode(reader, reader.uint32()));
                break;
            case 6:
                message.modalContent = $root.ModalContent.decode(reader, reader.uint32());
                break;
            case 7:
                message.urlForward = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a TemplateApp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof TemplateApp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {TemplateApp} TemplateApp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TemplateApp.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a TemplateApp message.
     * @function verify
     * @memberof TemplateApp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    TemplateApp.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.visible != null && message.hasOwnProperty("visible"))
            if (!$util.isString(message.visible))
                return "visible: string expected";
        if (message.templateAppId != null && message.hasOwnProperty("templateAppId"))
            if (!$util.isString(message.templateAppId))
                return "templateAppId: string expected";
        if (message.templateId != null && message.hasOwnProperty("templateId"))
            if (!$util.isInteger(message.templateId))
                return "templateId: integer expected";
        if (message.style != null && message.hasOwnProperty("style")) {
            let error = $root.Style.verify(message.style);
            if (error)
                return "style." + error;
        }
        if (message.buttons != null && message.hasOwnProperty("buttons")) {
            if (!Array.isArray(message.buttons))
                return "buttons: array expected";
            for (let i = 0; i < message.buttons.length; ++i) {
                let error = $root.Button.verify(message.buttons[i]);
                if (error)
                    return "buttons." + error;
            }
        }
        if (message.modalContent != null && message.hasOwnProperty("modalContent")) {
            let error = $root.ModalContent.verify(message.modalContent);
            if (error)
                return "modalContent." + error;
        }
        if (message.urlForward != null && message.hasOwnProperty("urlForward"))
            if (!$util.isString(message.urlForward))
                return "urlForward: string expected";
        return null;
    };

    /**
     * Creates a TemplateApp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof TemplateApp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {TemplateApp} TemplateApp
     */
    TemplateApp.fromObject = function fromObject(object) {
        if (object instanceof $root.TemplateApp)
            return object;
        let message = new $root.TemplateApp();
        if (object.visible != null)
            message.visible = String(object.visible);
        if (object.templateAppId != null)
            message.templateAppId = String(object.templateAppId);
        if (object.templateId != null)
            message.templateId = object.templateId | 0;
        if (object.style != null) {
            if (typeof object.style !== "object")
                throw TypeError(".TemplateApp.style: object expected");
            message.style = $root.Style.fromObject(object.style);
        }
        if (object.buttons) {
            if (!Array.isArray(object.buttons))
                throw TypeError(".TemplateApp.buttons: array expected");
            message.buttons = [];
            for (let i = 0; i < object.buttons.length; ++i) {
                if (typeof object.buttons[i] !== "object")
                    throw TypeError(".TemplateApp.buttons: object expected");
                message.buttons[i] = $root.Button.fromObject(object.buttons[i]);
            }
        }
        if (object.modalContent != null) {
            if (typeof object.modalContent !== "object")
                throw TypeError(".TemplateApp.modalContent: object expected");
            message.modalContent = $root.ModalContent.fromObject(object.modalContent);
        }
        if (object.urlForward != null)
            message.urlForward = String(object.urlForward);
        return message;
    };

    /**
     * Creates a plain object from a TemplateApp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof TemplateApp
     * @static
     * @param {TemplateApp} message TemplateApp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TemplateApp.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.buttons = [];
        if (options.defaults) {
            object.visible = "";
            object.templateAppId = "";
            object.templateId = 0;
            object.style = null;
            object.modalContent = null;
            object.urlForward = "";
        }
        if (message.visible != null && message.hasOwnProperty("visible"))
            object.visible = message.visible;
        if (message.templateAppId != null && message.hasOwnProperty("templateAppId"))
            object.templateAppId = message.templateAppId;
        if (message.templateId != null && message.hasOwnProperty("templateId"))
            object.templateId = message.templateId;
        if (message.style != null && message.hasOwnProperty("style"))
            object.style = $root.Style.toObject(message.style, options);
        if (message.buttons && message.buttons.length) {
            object.buttons = [];
            for (let j = 0; j < message.buttons.length; ++j)
                object.buttons[j] = $root.Button.toObject(message.buttons[j], options);
        }
        if (message.modalContent != null && message.hasOwnProperty("modalContent"))
            object.modalContent = $root.ModalContent.toObject(message.modalContent, options);
        if (message.urlForward != null && message.hasOwnProperty("urlForward"))
            object.urlForward = message.urlForward;
        return object;
    };

    /**
     * Converts this TemplateApp to JSON.
     * @function toJSON
     * @memberof TemplateApp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TemplateApp.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return TemplateApp;
})();

export const Trigger = $root.Trigger = (() => {

    /**
     * Properties of a Trigger.
     * @exports ITrigger
     * @interface ITrigger
     * @property {string|null} [triggerType] Trigger triggerType
     * @property {string|null} [triggerId] Trigger triggerId
     */

    /**
     * Constructs a new Trigger.
     * @exports Trigger
     * @classdesc Represents a Trigger.
     * @implements ITrigger
     * @constructor
     * @param {ITrigger=} [properties] Properties to set
     */
    function Trigger(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Trigger triggerType.
     * @member {string} triggerType
     * @memberof Trigger
     * @instance
     */
    Trigger.prototype.triggerType = "";

    /**
     * Trigger triggerId.
     * @member {string} triggerId
     * @memberof Trigger
     * @instance
     */
    Trigger.prototype.triggerId = "";

    /**
     * Creates a new Trigger instance using the specified properties.
     * @function create
     * @memberof Trigger
     * @static
     * @param {ITrigger=} [properties] Properties to set
     * @returns {Trigger} Trigger instance
     */
    Trigger.create = function create(properties) {
        return new Trigger(properties);
    };

    /**
     * Encodes the specified Trigger message. Does not implicitly {@link Trigger.verify|verify} messages.
     * @function encode
     * @memberof Trigger
     * @static
     * @param {ITrigger} message Trigger message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Trigger.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.triggerType != null && Object.hasOwnProperty.call(message, "triggerType"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.triggerType);
        if (message.triggerId != null && Object.hasOwnProperty.call(message, "triggerId"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.triggerId);
        return writer;
    };

    /**
     * Encodes the specified Trigger message, length delimited. Does not implicitly {@link Trigger.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Trigger
     * @static
     * @param {ITrigger} message Trigger message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Trigger.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Trigger message from the specified reader or buffer.
     * @function decode
     * @memberof Trigger
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Trigger} Trigger
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Trigger.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Trigger();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.triggerType = reader.string();
                break;
            case 2:
                message.triggerId = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Trigger message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Trigger
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Trigger} Trigger
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Trigger.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Trigger message.
     * @function verify
     * @memberof Trigger
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Trigger.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.triggerType != null && message.hasOwnProperty("triggerType"))
            if (!$util.isString(message.triggerType))
                return "triggerType: string expected";
        if (message.triggerId != null && message.hasOwnProperty("triggerId"))
            if (!$util.isString(message.triggerId))
                return "triggerId: string expected";
        return null;
    };

    /**
     * Creates a Trigger message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Trigger
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Trigger} Trigger
     */
    Trigger.fromObject = function fromObject(object) {
        if (object instanceof $root.Trigger)
            return object;
        let message = new $root.Trigger();
        if (object.triggerType != null)
            message.triggerType = String(object.triggerType);
        if (object.triggerId != null)
            message.triggerId = String(object.triggerId);
        return message;
    };

    /**
     * Creates a plain object from a Trigger message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Trigger
     * @static
     * @param {Trigger} message Trigger
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Trigger.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.triggerType = "";
            object.triggerId = "";
        }
        if (message.triggerType != null && message.hasOwnProperty("triggerType"))
            object.triggerType = message.triggerType;
        if (message.triggerId != null && message.hasOwnProperty("triggerId"))
            object.triggerId = message.triggerId;
        return object;
    };

    /**
     * Converts this Trigger to JSON.
     * @function toJSON
     * @memberof Trigger
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Trigger.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return Trigger;
})();

export const google = $root.google = (() => {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    const google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        const protobuf = {};

        protobuf.Timestamp = (function() {

            /**
             * Properties of a Timestamp.
             * @memberof google.protobuf
             * @interface ITimestamp
             * @property {number|Long|null} [seconds] Timestamp seconds
             * @property {number|null} [nanos] Timestamp nanos
             */

            /**
             * Constructs a new Timestamp.
             * @memberof google.protobuf
             * @classdesc Represents a Timestamp.
             * @implements ITimestamp
             * @constructor
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             */
            function Timestamp(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Timestamp seconds.
             * @member {number|Long} seconds
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Timestamp nanos.
             * @member {number} nanos
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.nanos = 0;

            /**
             * Creates a new Timestamp instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             * @returns {google.protobuf.Timestamp} Timestamp instance
             */
            Timestamp.create = function create(properties) {
                return new Timestamp(properties);
            };

            /**
             * Encodes the specified Timestamp message. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.seconds != null && Object.hasOwnProperty.call(message, "seconds"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
                if (message.nanos != null && Object.hasOwnProperty.call(message, "nanos"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
                return writer;
            };

            /**
             * Encodes the specified Timestamp message, length delimited. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Timestamp();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.seconds = reader.int64();
                        break;
                    case 2:
                        message.nanos = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Timestamp message.
             * @function verify
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Timestamp.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (!$util.isInteger(message.seconds) && !(message.seconds && $util.isInteger(message.seconds.low) && $util.isInteger(message.seconds.high)))
                        return "seconds: integer|Long expected";
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    if (!$util.isInteger(message.nanos))
                        return "nanos: integer expected";
                return null;
            };

            /**
             * Creates a Timestamp message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Timestamp} Timestamp
             */
            Timestamp.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Timestamp)
                    return object;
                let message = new $root.google.protobuf.Timestamp();
                if (object.seconds != null)
                    if ($util.Long)
                        (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
                    else if (typeof object.seconds === "string")
                        message.seconds = parseInt(object.seconds, 10);
                    else if (typeof object.seconds === "number")
                        message.seconds = object.seconds;
                    else if (typeof object.seconds === "object")
                        message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
                if (object.nanos != null)
                    message.nanos = object.nanos | 0;
                return message;
            };

            /**
             * Creates a plain object from a Timestamp message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.Timestamp} message Timestamp
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Timestamp.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.seconds = options.longs === String ? "0" : 0;
                    object.nanos = 0;
                }
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (typeof message.seconds === "number")
                        object.seconds = options.longs === String ? String(message.seconds) : message.seconds;
                    else
                        object.seconds = options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds;
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    object.nanos = message.nanos;
                return object;
            };

            /**
             * Converts this Timestamp to JSON.
             * @function toJSON
             * @memberof google.protobuf.Timestamp
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Timestamp.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Timestamp;
        })();

        protobuf.Any = (function() {

            /**
             * Properties of an Any.
             * @memberof google.protobuf
             * @interface IAny
             * @property {string|null} [type_url] Any type_url
             * @property {Uint8Array|null} [value] Any value
             */

            /**
             * Constructs a new Any.
             * @memberof google.protobuf
             * @classdesc Represents an Any.
             * @implements IAny
             * @constructor
             * @param {google.protobuf.IAny=} [properties] Properties to set
             */
            function Any(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Any type_url.
             * @member {string} type_url
             * @memberof google.protobuf.Any
             * @instance
             */
            Any.prototype.type_url = "";

            /**
             * Any value.
             * @member {Uint8Array} value
             * @memberof google.protobuf.Any
             * @instance
             */
            Any.prototype.value = $util.newBuffer([]);

            /**
             * Creates a new Any instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Any
             * @static
             * @param {google.protobuf.IAny=} [properties] Properties to set
             * @returns {google.protobuf.Any} Any instance
             */
            Any.create = function create(properties) {
                return new Any(properties);
            };

            /**
             * Encodes the specified Any message. Does not implicitly {@link google.protobuf.Any.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Any
             * @static
             * @param {google.protobuf.IAny} message Any message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Any.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.type_url != null && Object.hasOwnProperty.call(message, "type_url"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.type_url);
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.value);
                return writer;
            };

            /**
             * Encodes the specified Any message, length delimited. Does not implicitly {@link google.protobuf.Any.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Any
             * @static
             * @param {google.protobuf.IAny} message Any message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Any.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Any message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Any
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Any} Any
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Any.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Any();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.type_url = reader.string();
                        break;
                    case 2:
                        message.value = reader.bytes();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an Any message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Any
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Any} Any
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Any.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an Any message.
             * @function verify
             * @memberof google.protobuf.Any
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Any.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.type_url != null && message.hasOwnProperty("type_url"))
                    if (!$util.isString(message.type_url))
                        return "type_url: string expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!(message.value && typeof message.value.length === "number" || $util.isString(message.value)))
                        return "value: buffer expected";
                return null;
            };

            /**
             * Creates an Any message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Any
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Any} Any
             */
            Any.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Any)
                    return object;
                let message = new $root.google.protobuf.Any();
                if (object.type_url != null)
                    message.type_url = String(object.type_url);
                if (object.value != null)
                    if (typeof object.value === "string")
                        $util.base64.decode(object.value, message.value = $util.newBuffer($util.base64.length(object.value)), 0);
                    else if (object.value.length)
                        message.value = object.value;
                return message;
            };

            /**
             * Creates a plain object from an Any message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Any
             * @static
             * @param {google.protobuf.Any} message Any
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Any.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.type_url = "";
                    if (options.bytes === String)
                        object.value = "";
                    else {
                        object.value = [];
                        if (options.bytes !== Array)
                            object.value = $util.newBuffer(object.value);
                    }
                }
                if (message.type_url != null && message.hasOwnProperty("type_url"))
                    object.type_url = message.type_url;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = options.bytes === String ? $util.base64.encode(message.value, 0, message.value.length) : options.bytes === Array ? Array.prototype.slice.call(message.value) : message.value;
                return object;
            };

            /**
             * Converts this Any to JSON.
             * @function toJSON
             * @memberof google.protobuf.Any
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Any.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Any;
        })();

        return protobuf;
    })();

    return google;
})();

export { $root as default };
