
import {
    defineComponent
} from 'vue';
import MobileEntry from '../../../sharedComponents/MobileEntry/MobileEntry.vue';

export default defineComponent({
    name: 'SnipeModal',
    components: {
        MobileEntry
    },
    props: {
        res: Number,
        classes: {
            type: Object,
            required: false
        },
        mobileEntry: Boolean,
        templateId: Number
    }
});
