import { getToken } from '@/services/eventAPI';
import { LiveHandlers, LiveEventMap } from '@/services/models';
import * as ga from '@/widgets/googleAnalytics';
import { toBool } from '@/helpers/helpers';

class LiveApiService {
    private static _instance: LiveApiService;

    static getInstance (): LiveApiService {
        return LiveApiService._instance
            ? LiveApiService._instance
            : this.createInstance();
    }

    private static createInstance (): LiveApiService {
        LiveApiService._instance = new LiveApiService();
        return LiveApiService._instance;
    }

    private constructor () {
        this.ws = null;
        this.canvasProgramId = '';
        this.retryCount = 0;
        this.eventMap = new Array<LiveEventMap>();
        this._isConnectionActive = false;
        this.websocketUrl = window.config?.VUE_WS_LIVE_URL || process.env?.VUE_WS_LIVE_URL;
    }

    private readonly websocketUrl: string;
    private ws: WebSocket | null;
    private retryCount: number;
    private canvasProgramId: string;
    private eventMap: Array<LiveEventMap>;

    private _isConnectionActive: boolean;
    public get isConnectionActive (): boolean {
        return this._isConnectionActive;
    }

    public setCanvasProgramId (canvasProgramId: string) {
        this.canvasProgramId = canvasProgramId;
    }

    private async connect (): Promise<LiveApiService> {
        if (this._isConnectionActive || this.shouldDisableLiveApi()) {
            return this;
        }
        const token = await getToken(this.canvasProgramId);
        this.ws = new WebSocket(`${this.websocketUrl}/api/v1/live/${this.canvasProgramId}/subscribe?token=${token}`);
        this.ws.onopen = () => this.onOpen();
        this.ws.onmessage = (message: any) => this.onMessage(message);
        this.ws.onclose = () => this.onClose();
        return this;
    }

    public async disconnect (): Promise<LiveApiService> {
        this._isConnectionActive = false;
        await this.ws?.close();
        await this.unsubscribeAll();
        this.ws = null;
        this.canvasProgramId = '';
        return this;
    }

    public async send (handler: string, action: string, payload: {[k: string]: any}): Promise<LiveApiService> {
        const message = LiveApiService.serializeMessage(handler, action, payload);
        await this.ws?.send(message);
        return this;
    }

    public async subscribe (eventMap: LiveEventMap): Promise<LiveApiService> {
        await this.connect();
        this.eventMap.push(eventMap);
        return this;
    }

    public isSubscribed (eventMap: LiveEventMap): boolean {
        return this.eventMap.indexOf(eventMap) !== -1;
    }

    public async unsubscribe (eventMap: LiveEventMap): Promise<LiveApiService> {
        const i = this.eventMap.indexOf(eventMap);
        if (i >= 0) {
            this.eventMap.splice(i, 1);
        }
        return this;
    }

    public async unsubscribeSet (eventMap: Set<LiveEventMap> | Array<LiveEventMap>): Promise<LiveApiService> {
        const eventArray = eventMap instanceof Array
            ? eventMap
            : Array.from(eventMap);
        if (eventArray) {
            eventArray.every(item => this.unsubscribe(item));
        }
        return this;
    }

    public async unsubscribeAllByHandler (handler: LiveHandlers): Promise<LiveApiService> {
        const handlerCallbacks = this.eventMap.filter(map => map.handler === handler);
        if (handlerCallbacks) {
            handlerCallbacks.every(map => this.unsubscribe(map));
        }
        return this;
    }

    private async unsubscribeAll (): Promise<LiveApiService> {
        this.eventMap = new Array<LiveEventMap>();
        return this;
    }

    private onOpen (): void {
        this._isConnectionActive = true;
        if (this.eventMap) {
            this.eventMap.every(eventMap => {
                if (eventMap.onOpen) {
                    eventMap.onOpen();
                }
            });
        }
    }

    private async onMessage (message: any) {
        try {
            const { handler, action, ...data } = LiveApiService.parseMessage(message.data);
            const callbacks = this.eventMap.filter(map => map.handler === handler);
            if (callbacks.length > 0) {
                if ('data' in data) {
                    callbacks.every(map => map.onMessage(action, data.data));
                } else {
                    callbacks.every(map => map.onMessage(action, data));
                }
            }
        } catch (e) {
            ga.track('live api on message handler error', 'LiveApi');
        }
    }

    private onClose (): void {
        this._isConnectionActive = false;
        if (this.eventMap) {
            this.eventMap.every(eventMap => {
                if (eventMap.onClose) {
                    eventMap.onClose();
                }
            });
        }
    }

    private static serializeMessage (handler: string, action: string, payload: {[k: string]: any}) {
        payload.handler = handler;
        payload.action = action;
        return JSON.stringify(payload);
    }

    private static parseMessage (message: any) {
        return typeof message === 'string' && message.includes('{')
            ? JSON.parse(message)
            : message;
    }

    private shouldDisableLiveApi = (): boolean => this.websocketUrl == null
        ? true
        : toBool(window.config?.DISABLE_LIVE_API || process.env?.DISABLE_LIVE_API);
}

const LiveApi = LiveApiService.getInstance();
export default LiveApi;
