import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Snipe = _resolveComponent("Snipe")
  const _component_TemplateApp = _resolveComponent("TemplateApp")

  return (!_ctx.loading && !_ctx.liveFireWaiting)
    ? (_openBlock(), _createBlock("div", {
        class: "app",
        key: _ctx.eventId
      }, [
        _createVNode("div", null, [
          (_ctx.launchWidget === _ctx.WidgetType.SNIPE)
            ? (_openBlock(), _createBlock(_component_Snipe, {
                key: 0,
                onClose: _ctx.close
              }, null, 8, ["onClose"]))
            : _createCommentVNode("", true),
          ((_ctx.snipeIsLoaded && _ctx.eventType === 'SnipeAndTemplateApp') || _ctx.launchWidget === _ctx.WidgetType.TEMPLATE_APP)
            ? (_openBlock(), _createBlock(_component_TemplateApp, {
                key: 1,
                onClose: _ctx.close
              }, null, 8, ["onClose"]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}