export type State = {
    resolution: number;
    systemNotification: object;
    assetBaseUrl: string;
    event: any;
    renderedWidget: string;
    relaunchedWidget: string;
    snipeIsLoaded: boolean;
    templateAppIsLoaded: boolean;
    ctaButtonPress: string;
    externalAppTarget: string;
    tcOpen: boolean;
    notificationSettingsOpen: boolean;
    meOpen: boolean;
    tcLoaded: boolean;
    interactionStamp: string;
    widgetTimeout: number;
    timeoutLaunch: boolean;
    keyState: string;
    liveFire: boolean;
    templateAppVoting: boolean;
};

/* Live Models */
export enum LiveHandlers {
    VOTING = 'Voting',
    LIVEFIRE = 'LiveFire'
}
export type LiveOnMessageCallback = (action: string, data: {[k: string]: any}) => Promise<void>;
export type LiveOnConnectionChangeCallback = () => void;
export type LiveEventMap = {
    handler: LiveHandlers;
    onMessage: LiveOnMessageCallback;
    onOpen: LiveOnConnectionChangeCallback | null;
    onClose: LiveOnConnectionChangeCallback | null;
};
