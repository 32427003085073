

import { defineComponent, computed } from 'vue';

export default defineComponent({

  name: 'Icon',

  props: {
    icon: String,
    folder: String,
    extraClass: String
  },

  setup (props) {
    const assetUrl = computed(() =>
      'https://static.vizio-is.com/prod/assets/SystemUI/' +
      props.folder + '/' +
      props.icon + '.svg'
    );

    return {
      assetUrl
    };
  }
});

