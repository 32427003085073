import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 4,
  class: "listener-widget"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SnipeModal = _resolveComponent("SnipeModal")
  const _component_NotificationSettings = _resolveComponent("NotificationSettings")
  const _component_SnipeButton = _resolveComponent("SnipeButton")
  const _component_VotingResults = _resolveComponent("VotingResults")
  const _component_Icon = _resolveComponent("Icon")
  const _component_SystemNotificationText = _resolveComponent("SystemNotificationText")

  return (_openBlock(), _createBlock("div", {
    ref: "snipeRef",
    id: "id-snipe",
    class: ["snipe", {
            animateEnter: !_ctx.isInactive,
            'animateExit': false,
            'alexa-listener-widget': _ctx.dynamicItems.alexaListening,
            'show-notification-settings': _ctx.showNotificationSettings
        }],
    style: _ctx.cssProps
  }, [
    (_ctx.meExists)
      ? (_openBlock(), _createBlock(_component_SnipeModal, {
          key: 0,
          mobileEntry: _ctx.meShown,
          classes: { 'animate-in': _ctx.modalAnimate },
          widget: "snipe",
          templateId: _ctx.templateId,
          res: _ctx.resolution
        }, null, 8, ["mobileEntry", "classes", "templateId", "res"]))
      : _createCommentVNode("", true),
    (_ctx.showNotificationSettings)
      ? (_openBlock(), _createBlock(_component_NotificationSettings, {
          key: 1,
          onCloseSettings: _ctx.hideNotificationSettings,
          onRunTimestamp: _ctx.runTimeStamp
        }, null, 8, ["onCloseSettings", "onRunTimestamp"]))
      : _createCommentVNode("", true),
    (!_ctx.showVotingResults)
      ? (_openBlock(), _createBlock("div", {
          key: 2,
          class: ["snipe-button-container", { 'sui-button-container': _ctx.isSystemNotification }]
        }, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.buttons, (button, i) => {
            return (_openBlock(), _createBlock(_component_SnipeButton, {
              ref: button.type,
              buttonData: button,
              isSystemNotification: _ctx.isSystemNotification,
              isVotingWidget: _ctx.isVotingWidget,
              key: i,
              index: i,
              id: 'id-snipe-snipe-button-' + i,
              focusable: true,
              onVotePressed: _ctx.handleVotePress
            }, null, 8, ["buttonData", "isSystemNotification", "isVotingWidget", "index", "id", "onVotePressed"]))
          }), 128)),
          (_ctx.showNotificationSettings)
            ? (_openBlock(), _createBlock(_component_SnipeButton, {
                key: 0,
                isSystemNotification: "",
                icon: "gear",
                buttonData: {
                    rendered: true,
                    label: '',
                    type: 'icon',
                },
                focusable: !_ctx.notificationSettingsOpen,
                onIconClick: _ctx.openNotificationSettings
              }, null, 8, ["focusable", "onIconClick"]))
            : _createCommentVNode("", true)
        ], 2))
      : (_openBlock(), _createBlock(_component_VotingResults, {
          key: 3,
          results: _ctx.votingResults,
          totalVotes: _ctx.totalVotes
        }, null, 8, ["results", "totalVotes"])),
    (_ctx.isListenerWidget || _ctx.isDynamicSystemNotification)
      ? (_openBlock(), _createBlock("div", _hoisted_1, [
          (_ctx.isListenerWidget)
            ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
                (_ctx.dynamicItems.iconRing)
                  ? (_openBlock(), _createBlock(_component_Icon, {
                      key: 0,
                      icon: _ctx.dynamicItems.iconRing,
                      folder: "Rings"
                    }, null, 8, ["icon"]))
                  : _createCommentVNode("", true),
                (_ctx.dynamicItems.iconInner)
                  ? (_openBlock(), _createBlock(_component_Icon, {
                      key: 1,
                      icon: _ctx.dynamicItems.iconInner,
                      folder: "Icons",
                      extraClass: "icon-inner"
                    }, null, 8, ["icon"]))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          _createVNode(_component_SystemNotificationText, {
            dynamicItems: _ctx.dynamicItems,
            isDynamicSystemNotification: ""
          }, null, 8, ["dynamicItems"]),
          (_ctx.dynamicItems?.alexaListening && !_ctx.isDynamicSystemNotification)
            ? (_openBlock(), _createBlock(_component_Icon, {
                key: 1,
                icon: "animated-alexa-bar",
                folder: "Backgrounds",
                extraClass: "alexa-bar"
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 6))
}