
import {
    defineComponent,
    inject,
    computed
} from 'vue';
import SnipeButton from '../SnipeButton/SnipeButton.vue';

export default defineComponent({
    name: 'SystemNotificationText',

    props: {
        dynamicItems: Object,
        isDynamicSystemNotification: Boolean
    },

    components: { SnipeButton },

    setup (props, { emit }) {
        const store = inject<any>('store');

        const notificationSettingsOpen = computed(() => store.state.notificationSettingsOpen);
        const closeSettings = () => {
            emit('close');
        };

        return {
            closeSettings,
            notificationSettingsOpen,
            showNotificationSettings: store.state.event.showNotificationSettings
        };
    }
});

