
import {
    ref,
    watch,
    inject,
    computed,
    onMounted,
    defineComponent,
    onUnmounted
} from 'vue';
import { setIframeSize, ButtonNavigation } from 'vizio-vui-tv';
import { convertStylesTo720 } from '@/helpers/helpers';
import { VotingService } from '@/services/votingService';
import { verifySnipe } from '@/data/protos/helpers';
import SystemNotificationText from './SystemNotificationText/SystemNotificationText.vue';
import SnipeButton from './SnipeButton/SnipeButton.vue';
import SnipeModal from './SnipeModal/SnipeModal.vue';
import VotingResults from '@/widgets/sharedComponents/VotingResults/VotingResults.vue';
import NotificationSettings from '@/widgets/sharedComponents/NotificationSettings/NotificationSettings.vue';
import Icon from '@/assets/Icon.vue';
import * as ga from '@/widgets/googleAnalytics';
import { LiveEventMap, LiveHandlers } from '@/services/models';

export default defineComponent({
    components: {
        SystemNotificationText,
        SnipeButton,
        SnipeModal,
        VotingResults,
        NotificationSettings,
        Icon
    },

    setup (props, { emit }) {
        const store = inject<any>('store');

        const isSystemNotification = store.state.systemNotification.active;
        const isListenerWidget = store.state.systemNotification.listenerWidget;
        const isVotingWidget = store.state.systemNotification.votingWidget;
        const votingService: VotingService = new VotingService();
        const listenerBG = 'https://static.vizio-is.com/prod/assets/SystemUI/Backgrounds/black-fade-background.svg';
        const snipeRef = ref(null);
        const isInactive = ref(false);
        const meExists = ref(false);
        const meShown = ref(false);
        const meButton = ref(null);
        const modalAnimate = ref(false);
        const votingResults = ref([{ label: '', selected: false, votes: 0, progress: 0 }]);
        const showVotingResults = ref(false);
        const notificationSettingsOpen = computed(() => store.state.notificationSettingsOpen);
        const totalVotes = ref(0);

        const keyState = computed(() => store.state.keyState);
        const assetUrl = computed(() => store.state.assetBaseUrl + (isSystemNotification ? 'system-notifications/' : 'snipes/'));
        const resolution = computed(() => store.state.resolution);
        const invisible = computed(() => store.state.event.invisible);
        const eventAssets = computed(() => store.state.event.assets);
        const isDisplayed = computed(() => store.state.renderedWidget === 'snipe');
        const widgetTimeout = computed(() => store.state.widgetTimeout);

        const modalTimer = 200;
        const bgImage = new Image();
        const buttonNav = new ButtonNavigation(snipeRef);
        const timeout: any = ref(0);

        let templateId: any;
        let snipeAssets: any;
        let modalTimeout: any;
        let inactivityTimer: any;

        for (const [key, value] of Object.entries(eventAssets.value)) {
            if (key.includes('snipe')) {
                snipeAssets = value;
                templateId = snipeAssets.templateId;
                verifySnipe(snipeAssets);
                setIframeSize(templateId);
            }
        }

        const buttons: any = ref(snipeAssets?.buttons);
        const isDynamicSystemNotification = store.state.systemNotification.active && snipeAssets.template === 202;
        const dynamicItems = ref({
            textTop: '',
            textBottom: '',
            iconRing: '',
            iconInner: '',
            alexaListening: false
        });

        let snipeStyle;

        if (resolution.value === 720 && !isSystemNotification) { // SYSTEM NOTIFICATION NOT YET SUPPORTED FOR 1080
            snipeStyle = convertStylesTo720(snipeAssets?.style);
        } else {
            snipeStyle = snipeAssets?.style;
        }

        if (isSystemNotification) {
            require('@/assets/fonts/BentonSans.scss');
        }

        let cssProps;

        if (isListenerWidget) {
            cssProps = {
                '--visible-snipe': invisible.value ? 'hidden' : 'visible',
                '--height': '128px',
                '--width': '1280px',
                '--bottom': '0px',
                '--animation-enter': 'SlideFromBottom',
                '--animation-exit': 'SlideFromBottom-Exit',
                '--animation-offset-x': '1280px',
                '--animation-offset-y': '128px',
                'background-image': 'url(' + listenerBG + ')'
            };
        } else if (isSystemNotification) {
            cssProps = {
                '--visible-snipe': invisible.value ? 'hidden' : 'visible',
                '--height': '195px',
                '--width': '1280px',
                '--bottom': '0px',
                '--animation-enter': 'SlideFromBottom',
                '--animation-exit': 'SlideFromBottom-Exit',
                '--animation-offset-x': '1280px',
                '--animation-offset-y': '195px',
                'background-image': 'url(' + assetUrl.value + snipeAssets?.style?.backgroundImage + ')'
            };
        } else {
            cssProps = {
                ...snipeStyle,
                '--visible-snipe': invisible.value ? 'hidden' : 'visible',
                '--animation-enter': snipeAssets?.animation?.enter,
                '--animation-exit': `${snipeAssets?.animation?.enter}-Exit`,
                '--animation-offset-x': `-${snipeAssets?.style?.width}`,
                '--animation-offset-y': `-${snipeAssets?.style?.height}`,
                'background-image': 'url(' + assetUrl.value + snipeAssets?.style?.backgroundImage + ')'
            };
        }

        // Only show snipe after background image is loaded.
        bgImage.addEventListener('load', async () => {
            await store.dispatch('snipeIsLoaded', true);
            bgImage.onload = null; // Garbage collect bgImage.
            bgImage.src = '';
        });
        bgImage.src = isListenerWidget ? listenerBG : assetUrl.value + snipeAssets?.style?.backgroundImage;

        const close = () => {
            isInactive.value = true;
            emit('close', true);
        };

        const startTimer = () => {
            inactivityTimer = setTimeout(() => {
                if (isDisplayed.value === true) {
                    close();
                }
            }, widgetTimeout.value * 1000);
        };

        const resetTimer = () => {
            clearTimeout(inactivityTimer);
            isInactive.value = false;
            startTimer();
        };

        const meAnimateIn = () => {
            meShown.value = true;
            modalAnimate.value = true;
            buttonNav.unsetElements();
        };

        const meAnimateOut = () => {
            buttonNav.unsetElements();
            buttonNav.initialize();
            modalAnimate.value = false;
            clearTimeout(modalTimeout);
            modalTimeout = setTimeout(() => {
                meShown.value = false;
            }, modalTimer);
        };

        const openNotificationSettings = () => {
            buttonNav.unsetElements();
            store.dispatch('notificationSettingsOpen', true);
        };

        const hideNotificationSettings = async () => {
            store.dispatch('notificationSettingsOpen', false);
            clearTimeout(timeout.value);
            // Waiting for buttons to get focusable class back
            timeout.value = setTimeout(() => {
                buttonNav.initialize();
            }, 10);
        };

        const runTimeStamp = () => {
            const timeStamp = (new Date().getMinutes()) + (new Date().getSeconds() / 100);
            store.dispatch('interactionStamp', timeStamp);
            ga.track(keyState.value, snipeAssets?.snipeId + '-key_event');
            resetTimer();
        };

        const handleKeyEvent = (keyEvent: string) => {
            if (keyEvent === 'back' && !meShown.value && !notificationSettingsOpen.value) {
                close();
            } else {
                buttonNav.onPress(keyEvent);
                runTimeStamp();
            }
        };

        const handleVotePress = async (selection: string) => {
            await votingService?.castVote(selection);
        };

        const handleVoteMessage = async (action: string, results: {[k: string]: any} = {}) => {
            switch (action) {
                case 'Close Poll':
                case 'Results':
                    votingResults.value = votingService.tallyResults(results, snipeAssets.buttons);
                    totalVotes.value = votingService.totalVotes;
                    buttons.value = snipeAssets?.buttons?.filter((btn: any) => btn.type === 'ctaButton');
                    showVotingResults.value = true;
                    break;
            }
        };

        onMounted(async () => {
            buttonNav.initialize();
            meExists.value = buttons.value && buttons.value.some((btn: any) => btn.type === 'meButton');

            if (isVotingWidget) {
                const votingEventMap: LiveEventMap = {
                    handler: LiveHandlers.VOTING,
                    onOpen: null,
                    onMessage: handleVoteMessage,
                    onClose: null
                };
                await votingService.pollSubscribe(votingEventMap);
            }
            // ENABLE WHEN VOICE AND DYNAMIC WIDGET TEXT IS ADDED TO SYSTEM UI
            // START ------- //
            // if (isDynamicSystemNotification || isListenerWidget) {
            //     window.addEventListener('message', (e: any) => {
            //         const r = e.data.suiDynamic; // R = Ready // Correct Message Type
            //         const t = e.data.type;
            //         if (r && t === 'dynamic-system-notification') {
            //             dynamicItems.value = {
            //                 textTop: e.data.textTop,
            //                 textBottom: e.data.textBottom,
            //                 iconRing: '',
            //                 iconInner: '',
            //                 alexaListening: false
            //             };
            //         } else if (r && t === 'alexa-listener') {
            //             dynamicItems.value = {
            //                 textTop: e.data.textTop,
            //                 textBottom: e.data.textBottom,
            //                 // iconRing: 'animated-alexa-ring',
            //                 iconRing: '',
            //                 iconInner: 'alexa-logo',
            //                 alexaListening: true
            //             };
            //         } else if (r && t === 'vizio-listener') {
            //             dynamicItems.value = {
            //                 textTop: e.data.textTop,
            //                 textBottom: e.data.textBottom,
            //                 iconRing: 'animated-vizio-ring',
            //                 iconInner: 'microphone',
            //                 alexaListening: false
            //             };
            //         } else if (r && e.data.type === 'open') { // THIS IS USED FOR DEMO PURPOSES, WILL BE DELETED AFTER DEMOS ARE COMPLETE
            //             open.value = !open.value;
            //         }
            //     });
            // }
            // END ------- //
        });

        onUnmounted(async () => {
            await votingService.pollUnsubscribe();
        });

        watch(() => keyState.value, (keyEvent) => {
            if (keyEvent && !notificationSettingsOpen.value) {
                handleKeyEvent(keyEvent);
            }
        });

        watch(() => store.state.meOpen, (meOpen) => {
            if (meOpen === true) {
                meAnimateIn();
            } else if (meOpen === false) {
                meAnimateOut();
            }
        });

        return {
            store,
            isSystemNotification,
            isListenerWidget,
            isVotingWidget,
            isDynamicSystemNotification,
            dynamicItems,
            buttons,
            meShown,
            meExists,
            meButton,
            cssProps,
            snipeRef,
            resolution,
            isInactive,
            resetTimer,
            meAnimateIn,
            modalAnimate,
            votingResults,
            votingService,
            showVotingResults,
            notificationSettingsOpen,
            openNotificationSettings,
            hideNotificationSettings,
            handleVoteMessage,
            handleVotePress,
            totalVotes,
            templateId: snipeAssets?.templateId,
            showNotificationSettings: store.state.event.showNotificationSettings,
            runTimeStamp
        };
    }
});

