
import { defineComponent, inject, ref, watch, computed, onMounted, nextTick } from 'vue';
import { ButtonNavigation } from 'vizio-vui-tv';
import SvgIcon from '@/assets/svg/SvgIcon.vue';

export default defineComponent({
    components: {
        SvgIcon
    },
    props: {
        widget: {
            type: String,
            required: true
        }
    },

    setup () {
        const store = inject<any>('store');

        const modalRef = ref(null);
        const showModal = ref(false);
        const phoneNumber = ref('');
        const defNum = '555-555-5555';
        phoneNumber.value = defNum;
        const submitDisabled = ref(true);
        const submitRef = ref(null);
        const buttonRef1 = ref(null);
        const buttonRefclear = ref(null);
        const buttonRefback = ref(null);

        const keyState = computed(() => store.state.keyState);
        const resolution = computed(() => store.state.resolution);
        const buttonNav = new ButtonNavigation(modalRef);

        const submitMobileNumber = () => {
            localStorage.setItem('mobileNumber', phoneNumber.value);
            store.dispatch('meOpen', false);
        };

        const handleKeyEvent = async (keyEvent: string) => {
            const remoteNumArr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'back'];
            const key: any = document.getElementsByClassName('focused')[0];
            let keyVal = key.value;

            if (remoteNumArr.includes(keyEvent)) {
                keyVal = keyEvent;
                const numKey = document.getElementsByClassName('key-' + keyVal)[0];
                const numKeyIndex = buttonNav.FOCUS_ELEMENTS.filter((elem: any) => elem.element === numKey)[0].index;
                await buttonNav.resetFocus(numKeyIndex);
            }

            if (
                (keyEvent === 'enter' && key.classList.contains('focused') && key.classList.contains('num-key')) ||
                remoteNumArr.includes(keyEvent)
            ) {
                let pn = phoneNumber.value;
                const digit = Number(keyVal) <= 9;

                if (pn === defNum) { // clearing the default before adding new numbers.
                    pn = '';
                }
                if (digit && pn.length < 12) {
                    pn = pn + keyVal;
                } else if (digit && pn.length === 12) {
                    pn = pn.slice(0, pn.length - 1) + keyVal;
                } else if (keyVal === 'back' && pn.length === 0) { // closes if user hits back and 0 numbers are input
                    store.dispatch('meOpen', false);
                } else if (keyVal === 'back' && (pn.length === 4 || pn.length === 8)) { // "ignores" the hypens if back on hyphen
                    pn = pn.slice(0, pn.length - 2);
                } else if (keyVal === 'back' && (pn.length <= 1)) {
                    pn = defNum;
                    buttonNav.resetFocus(0); // sets focus back to 1
                } else if (keyVal === 'back' && pn !== defNum) {
                    pn = pn.slice(0, pn.length - 1);
                } else if (keyVal === 'clear') {
                    pn = defNum;
                    buttonNav.resetFocus(0); // sets focus back to 1
                }

                if ((pn.length === 3 || pn.length === 7)) {
                    pn = pn + '-';
                }

                if (pn.length === 12 && pn !== defNum) {
                    submitDisabled.value = false;
                    buttonNav.resetFocus(12); // sets focus on submit button
                } else {
                    submitDisabled.value = true;
                }

                phoneNumber.value = pn;
            }

            if (keyEvent === 'enter' && keyVal === 'submit' && submitDisabled.value === false) { // submit button hit
                submitMobileNumber();
            }
        };

        const setLocalNumber = () => {
            phoneNumber.value = localStorage.mobileNumber;
            submitDisabled.value = false;
            buttonNav.resetFocus(12); // sets focus on submit button
        };

        watch(() => keyState.value, (keyEvent) => {
            if (keyEvent) {
                buttonNav.onPress(keyEvent);
                handleKeyEvent(keyEvent);
            }
        });

        onMounted(async () => {
            await nextTick();
            buttonNav.initialize();
            if (localStorage.mobileNumber) {
                setLocalNumber();
            }
        });

        return {
            modalRef,
            showModal,
            phoneNumber,
            instructions: 'Input Your Phone Number',
            keys: [1, 2, 3, 4, 5, 6, 7, 8, 9, 'clear', 0, 'back'],
            submitButtonText: 'Send The Text',
            disclaimer: 'Message & Data Rates May Apply',
            submitDisabled,
            defNum,
            submitRef,
            buttonRef1,
            buttonRefclear,
            buttonRefback,
            handleKeyEvent,
            submitMobileNumber,
            resolution
        };
    }
});
