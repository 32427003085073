import { Snipe, ITVEvent, TemplateApp } from '@/data/protos/ITV_event_compiled';

export const verifyEvent = (event: any) => {
    const error = ITVEvent.verify(event);
    if (error) {
        throw Error(error);
    }
};

export const verifySnipe = (snipe: any) => {
    const error = Snipe.verify(snipe);
    if (error) {
        throw Error(error);
    }
};

export const verifyTemplateApp = (templateApp: any) => {
    const error = TemplateApp.verify(templateApp);
    if (error) {
        throw Error(error);
    }
};
