
import {
    ref,
    watch,
    inject,
    provide,
    computed,
    onMounted,
    defineComponent,
    onUnmounted
} from 'vue';
import {
    setVideoSize,
    setIframeSize,
    ButtonNavigation,
    KeyPressIndicator
} from 'vizio-vui-tv';
import { convertStylesTo720 } from '@/helpers/helpers';
import { VotingService } from '@/services/votingService';
import { verifyTemplateApp } from '@/data/protos/helpers';
import TemplateAppButton from './TemplateAppButton/TemplateAppButton.vue';
import TemplateAppModal from './TemplateAppModal/TemplateAppModal.vue';
import VotingResults from '@/widgets/sharedComponents/VotingResults/VotingResults.vue';
import * as ga from '@/widgets/googleAnalytics';
import { LiveEventMap, LiveHandlers } from '@/services/models';

export default defineComponent({
    components: {
        TemplateAppButton,
        KeyPressIndicator,
        TemplateAppModal,
        VotingResults
    },

    emits: [
        'close'
    ],

    setup (props, { emit }) {
        const store = inject<any>('store');
        const isVotingWidget = store.state.templateAppVoting;
        const tcShown = ref(false);
        const meShown = ref(false);
        const tcButton = ref(null);
        const tcExists = ref(false);
        const meButton = ref(null);
        const meExists = ref(false);
        const modalAnimate = ref(false);
        const templateAppRef = ref(null);
        const templateAppInner = ref(null);
        const votingService: VotingService = new VotingService();
        const votingResults = ref([{ label: '', selected: false, votes: 0, progress: 0 }]);
        const showVotingResults = ref(false);
        const totalVotes = ref(0);

        const keyState = computed(() => store.state.keyState);
        const isLoaded = computed(() => store.state.templateAppIsLoaded);
        const assetUrl = computed(() => store.state.assetBaseUrl + 'template-apps/');
        const resolution = computed(() => store.state.resolution);
        const eventAssets = computed(() => store.state.event.assets);
        const isDisplayed = computed(() => store.state.renderedWidget === 'templateApp');
        const isHomeAsset = computed(() => store.state.event.homeAsset.includes('templateApp'));
        const widgetTimeout = computed(() => store.state.widgetTimeout);
        const relaunchedWidget = computed(() => store.state.relaunchedWidget);

        const modalTimer = 200;
        const bgImage = new Image();
        const buttonNav = new ButtonNavigation(templateAppInner);

        let modalTimeout: any;
        let inactivityTimer: any;
        let templateAppAssets: any;

        for (const [key, value] of Object.entries(eventAssets.value)) {
            if (key.includes('templateApp')) {
                templateAppAssets = value;
                verifyTemplateApp(value);
            }
        }

        const taStyle = resolution.value === 720 ? convertStylesTo720(templateAppAssets?.style) : templateAppAssets?.style;
        const buttons: any = ref(templateAppAssets?.buttons);

        if (isVotingWidget) {
            require('@/assets/fonts/BentonSans.scss');
            buttons.value = buttons.value.filter((btn: any) => btn.type !== 'ctaButton' && btn.type !== 'meButton');
        }

        const cssProps = {
            ...taStyle,
            '--visible-template-app': templateAppAssets?.visible,
            'background-image': 'url(' + assetUrl.value + templateAppAssets?.style?.backgroundImage + ')'
        };

        // Only show templateApp after background image is loaded.
        if (isDisplayed.value === true && templateAppAssets.style?.backgroundImage) {
            bgImage.addEventListener('load', async () => {
                await store.dispatch('templateAppIsLoaded', true);
                bgImage.onload = null; // Garbage collect bgImage.
                bgImage.src = '';
            });
            bgImage.src = assetUrl.value + templateAppAssets.style.backgroundImage;
        }

        // Clears the previous timer, unfocuses all elements, builds button, waits then animates in
        const tcAnimateIn = () => {
            tcShown.value = true;
            modalAnimate.value = true;
        };

        const meAnimateIn = () => {
            meShown.value = true;
            modalAnimate.value = true;
            buttonNav.unsetElements();
        };

        // Clears the previous timer, animates out, waits then destroys
        const tcAnimateOut = () => {
            clearTimeout(modalTimeout);
            modalAnimate.value = false;
            modalTimeout = setTimeout(() => {
                tcShown.value = false;
            }, modalTimer);
        };

        const meAnimateOut = () => {
            buttonNav.unsetElements();
            buttonNav.initialize();
            modalAnimate.value = false;
            clearTimeout(modalTimeout);
            modalTimeout = setTimeout(() => {
                meShown.value = false;
            }, modalTimer);
        };

        const close = () => {
            emit('close', true);
        };

        const startTimer = () => {
            inactivityTimer = setTimeout(() => {
                close();
            }, widgetTimeout.value * 1000);
        };

        const resetTimer = () => {
            clearTimeout(inactivityTimer);
            startTimer();
        };

        const launch = async () => {
            setIframeSize(templateAppAssets?.templateId);
            setVideoSize(templateAppAssets?.templateId);
            await store.dispatch('templateAppIsLoaded', true);

            if (!isHomeAsset.value && relaunchedWidget.value !== 'templateApp') {
                // Timer should not start automatically if the TemplateApp is displayed first; either
                // because it's the homeAsset or because the app was re-launched directly to the TemplateApp
                startTimer();
            }
        };

        const handleKeyEvent = (keyEvent: string) => {
            if (keyEvent === 'back') {
                if (tcShown.value) {
                    store.dispatch('tcOpen', false);
                    tcAnimateOut();
                } else if (!meShown.value) {
                    close();
                }
            } else {
                resetTimer();
                const timeStamp = (new Date().getMinutes()) + (new Date().getSeconds() / 100);
                store.dispatch('interactionStamp', timeStamp);
                ga.track(keyState.value, templateAppAssets?.templateAppId + '-key_event');

                if (!tcShown.value && !meShown.value) {
                    buttonNav.onPress(keyEvent);
                }
            }
        };

        const handleVotePress = async (selection: string) => {
            await votingService?.castVote(selection);
        };

        const handleVoteMessage = async (action: string, results: {[k: string]: any} = {}) => {
            switch (action) {
                case 'Close Poll':
                case 'Results':
                    votingResults.value = votingService.tallyResults(results, templateAppAssets.buttons);
                    totalVotes.value = votingService.totalVotes;
                    buttons.value = templateAppAssets?.buttons?.filter((btn: any) => btn.type === 'ctaButton');
                    showVotingResults.value = true;
                    break;
            }
        };

        onMounted(async () => {
            buttonNav.initialize();
            tcExists.value = true;
            meExists.value = buttons.value && buttons.value.some((btn: any) => btn.type === 'meButton');

            if (isDisplayed.value) {
                launch();
            }

            if (templateAppAssets.urlForward) {
                window.location.replace(templateAppAssets.urlForward);
            }
            if (isVotingWidget) {
                const votingEventMap: LiveEventMap = {
                    handler: LiveHandlers.VOTING,
                    onOpen: null,
                    onMessage: handleVoteMessage,
                    onClose: null
                };
                await votingService.pollSubscribe(votingEventMap);
            }
        });

        onUnmounted(async () => {
            await votingService.pollUnsubscribe();
        });

        watch(() => store.state.tcOpen, (tcOpen) => {
            if (tcOpen === true) {
                tcAnimateIn();
            }
        });

        watch(() => store.state.meOpen, (meOpen) => {
            if (meOpen === true) {
                meAnimateIn();
            } else if (meOpen === false) {
                meAnimateOut();
            }
        });

        watch(() => isDisplayed.value, () => {
            isDisplayed.value && launch();
        });

        watch(() => keyState.value, (keyEvent) => {
            if (keyEvent && isDisplayed.value) {
                handleKeyEvent(keyEvent);
            }
        });

        provide('templateAppAssets', templateAppAssets);

        return {
            launch,
            isVotingWidget,
            buttons,
            tcShown,
            meShown,
            isLoaded,
            cssProps,
            tcExists,
            keyState,
            tcButton,
            meExists,
            meButton,
            buttonNav,
            resetTimer,
            meAnimateIn,
            tcAnimateIn,
            tcAnimateOut,
            modalAnimate,
            votingResults,
            votingService,
            showVotingResults,
            handleVotePress,
            handleVoteMessage,
            totalVotes,
            templateAppRef,
            templateAppInner
        };
    }
});
