
import {
    defineComponent
} from 'vue';
import MobileEntry from '../../../sharedComponents/MobileEntry/MobileEntry.vue';
import TermsAndConditions from './TermsAndConditions.vue';

export default defineComponent({
    name: 'TemplateAppModal',
    components: {
        MobileEntry,
        TermsAndConditions
    },
    props: {
        classes: {
            type: Object,
            required: false
        },
        mobileEntry: Boolean,
        termsAndConditions: Boolean
    }
});
