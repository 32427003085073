import { createStore } from 'vuex';
import { State } from '@/services/models';

const data: State = {
    resolution: 0,
    systemNotification: {
        active: false,
        listenerWidget: false,
        votingWidget: false
    },
    assetBaseUrl: 'https://static.vizio-is.com/prod/assets/',
    event: null,
    renderedWidget: '',
    relaunchedWidget: '',
    snipeIsLoaded: false,
    templateAppIsLoaded: false,
    ctaButtonPress: '',
    externalAppTarget: '',
    tcOpen: false,
    tcLoaded: false,
    meOpen: false,
    notificationSettingsOpen: false,
    interactionStamp: '',
    widgetTimeout: 7,
    timeoutLaunch: true,
    keyState: '',
    liveFire: false,
    templateAppVoting: false
};

export const store = createStore({
    state: data,
    mutations: {

        setResolution: (state, resolution) => (state.resolution = resolution),

        setSystemNotification: (state, systemNotification) => (state.systemNotification = systemNotification),

        setAssetBaseUrl: (state) => (state.assetBaseUrl = state.assetBaseUrl + state.resolution + '/'),

        setEvent: (state, event) => (state.event = event),

        setRenderedWidget: (state, renderedWidget) => (state.renderedWidget = renderedWidget),

        setRelaunchedWidget: (state, relaunchedWidget) => (state.relaunchedWidget = relaunchedWidget),

        setSnipeIsLoaded: (state, boolean) => (state.snipeIsLoaded = boolean),

        setTemplateAppIsLoaded: (state, boolean) => (state.templateAppIsLoaded = boolean),

        setButtonPress: (state, targetType) => (state.ctaButtonPress = targetType),

        setExternalAppTarget: (state, stringifiedValues) => (state.externalAppTarget = stringifiedValues),

        setTcOpen: (state, boolean) => (state.tcOpen = boolean),

        setTcLoaded: (state, boolean) => (state.tcLoaded = boolean),

        setMeOpen: (state, boolean) => (state.meOpen = boolean),

        setNotificationSettingsOpen: (state, notificationSettingsOpen) => (state.notificationSettingsOpen = notificationSettingsOpen),

        setInteractionStamp: (state, stamp) => (state.interactionStamp = stamp),

        setKeyState: (state, keyState) => (state.keyState = keyState),

        setLiveFire: (state, liveFire) => (state.liveFire = liveFire),

        setTemplateAppVoting: (state, templateAppVoting) => (state.templateAppVoting = templateAppVoting)

    },

    actions: {

        resolution: ({ commit }, resolution) => {
            commit('setResolution', resolution);
            commit('setAssetBaseUrl');
        },

        systemNotification: ({ commit }, systemNotification) => commit('setSystemNotification', systemNotification),

        loadEvent: ({ commit }, event) => commit('setEvent', event),

        renderedWidget: ({ commit }, renderedWidget) => commit('setRenderedWidget', renderedWidget),

        relaunchedWidget: ({ commit }, relaunchedWidget) => commit('setRelaunchedWidget', relaunchedWidget),

        snipeIsLoaded: ({ commit }, boolean) => commit('setSnipeIsLoaded', boolean),

        templateAppIsLoaded: ({ commit }, boolean) => commit('setTemplateAppIsLoaded', boolean),

        ctaButtonPress: ({ commit }, targetType) => commit('setButtonPress', targetType),

        externalAppTarget: ({ commit }, stringifiedValues) => commit('setExternalAppTarget', stringifiedValues),

        tcOpen: ({ commit }, boolean) => commit('setTcOpen', boolean),

        tcLoaded: ({ commit }, boolean) => commit('setTcLoaded', boolean),

        meOpen: ({ commit }, boolean) => commit('setMeOpen', boolean),

        notificationSettingsOpen: ({ commit }, notificationSettingsOpen) => commit('setNotificationSettingsOpen', notificationSettingsOpen),

        interactionStamp: ({ commit }, stamp) => commit('setInteractionStamp', stamp),

        keyState: ({ commit }, keyState) => commit('setKeyState', keyState),

        liveFire: ({ commit }, liveFire) => commit('setLiveFire', liveFire),

        templateAppVoting: ({ commit }, templateAppVoting) => commit('setTemplateAppVoting', templateAppVoting)
    },
    modules: {}
});
