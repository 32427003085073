import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "lw-dynamic-text-top" }
const _hoisted_2 = { class: "lw-dynamic-text-bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SnipeButton = _resolveComponent("SnipeButton")

  return (_openBlock(), _createBlock("div", {
    class: ["lw-dynamic-text", { 'one-line-only': !_ctx.dynamicItems?.textBottom,
        'has-buttons': _ctx.isDynamicSystemNotification,
        'has-back-button': _ctx.showNotificationSettings
    }]
  }, [
    (_ctx.showNotificationSettings)
      ? (_openBlock(), _createBlock(_component_SnipeButton, {
          key: 0,
          isSystemNotification: "",
          icon: "back-arrow",
          buttonData: {
                rendered: true,
                label: '',
                type: 'icon',
            },
          focusable: _ctx.notificationSettingsOpen,
          onIconClick: _ctx.closeSettings
        }, null, 8, ["focusable", "onIconClick"]))
      : _createCommentVNode("", true),
    _createVNode("div", null, [
      _createVNode("div", _hoisted_1, _toDisplayString(_ctx.dynamicItems?.textTop), 1),
      _createVNode("div", _hoisted_2, _toDisplayString(_ctx.dynamicItems?.textBottom), 1)
    ])
  ], 2))
}