import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: ["lw-icon", _ctx.extraClass]
  }, [
    _createVNode("img", {
      src: _ctx.assetUrl,
      alt: _ctx.icon
    }, null, 8, ["src", "alt"])
  ], 2))
}