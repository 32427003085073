import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (typeof _ctx.icon === 'string')
    ? (_openBlock(), _createBlock("div", {
        key: 0,
        innerHTML: _ctx.svg,
        class: "svg-container"
      }, null, 8, ["innerHTML"]))
    : _createCommentVNode("", true)
}