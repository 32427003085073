export enum TargetType {
    SNIPE = 'snipe',
    TEMPLATE_APP = 'templateApp',
    TVAPP = 'tvApp'
}

export enum Enter {
    FADEIN = 'fadeIn',
    SLIDEFROMLEFT = 'slideFromLeft'
}

export enum Animate {
    BOUNCE = 'bounce'
}

export enum MessageType {
    KEY_EVENT = 'keyEvent',
    WHATS_ON = 'whatsOn'
}

export enum EventType {
    SNIPE = 'Snipe',
    TEMPLATE_APP = 'TemplateApp',
    SNIPE_AND_TEMPLATE_APP = 'SnipeAndTemplateApp',
    RELAUNCH = 'Relaunch'
}

export enum WidgetType {
    SNIPE = 'snipe',
    TEMPLATE_APP = 'templateApp',
}
