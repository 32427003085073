import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KeyPressIndicator = _resolveComponent("KeyPressIndicator")
  const _component_TemplateAppModal = _resolveComponent("TemplateAppModal")
  const _component_VotingResults = _resolveComponent("VotingResults")
  const _component_TemplateAppButton = _resolveComponent("TemplateAppButton")

  return (_openBlock(), _createBlock("div", {
    ref: "templateAppRef",
    id: "id-template-app",
    class: ["template-app", !_ctx.isLoaded ? 'show' : 'hide'],
    style: _ctx.cssProps
  }, [
    _createVNode(_component_KeyPressIndicator, { keyState: _ctx.keyState }, null, 8, ["keyState"]),
    (_ctx.meExists || _ctx.tcExists)
      ? (_openBlock(), _createBlock(_component_TemplateAppModal, {
          key: 0,
          mobileEntry: _ctx.meShown,
          termsAndConditions: _ctx.tcShown,
          classes: { 'animate-in': _ctx.modalAnimate }
        }, null, 8, ["mobileEntry", "termsAndConditions", "classes"]))
      : _createCommentVNode("", true),
    _createVNode("div", {
      ref: "templateAppInner",
      class: ["template-app-inner", { 'is-voting-widget': _ctx.isVotingWidget }]
    }, [
      (_ctx.showVotingResults)
        ? (_openBlock(), _createBlock(_component_VotingResults, {
            key: 0,
            results: _ctx.votingResults,
            totalVotes: _ctx.totalVotes,
            isTemplateApp: ""
          }, null, 8, ["results", "totalVotes"]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.buttons, (button, i) => {
        return (_openBlock(), _createBlock(_component_TemplateAppButton, {
          ref: button.type,
          buttonData: button,
          key: button.normalStyle.label + '-' + i,
          id: 'id-template-app-template-app-button-' + i,
          isVotingWidget: _ctx.isVotingWidget,
          onVotePressed: _ctx.handleVotePress,
          showVotingResults: _ctx.showVotingResults
        }, null, 8, ["buttonData", "id", "isVotingWidget", "onVotePressed", "showVotingResults"]))
      }), 128))
    ], 2)
  ], 6))
}