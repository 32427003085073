import { LiveEventMap, LiveHandlers } from '@/services/models';
import { toNumOrZero } from '@/helpers/helpers';
import LiveApi from '@/services/liveAPI';

export class VotingService {
    constructor () {
        this._isSubscribed = false;
        this._eventMap = null;
        this.totalVotes = 0;
        this.voteCast = '';
    }

    private _isSubscribed: boolean;
    private _eventMap: any;
    public totalVotes: number;
    public voteCast: string;

    public async pollSubscribe (eventMap: LiveEventMap) {
        if (!this._isSubscribed) {
            await LiveApi.subscribe(eventMap);
            this._eventMap = eventMap;
            this._isSubscribed = true;
        }
        return this;
    }

    public async castVote (option: string) {
        if (this._isSubscribed) {
            await LiveApi.send(LiveHandlers.VOTING, 'Cast', { 'option': option });
            this.voteCast = option;
        }
        return this;
    }

    public tallyResults (results: {[k: string]: any}, buttons: any) {
        this.totalVotes = Object.values(results).reduce((totalCount: number, x: string) => totalCount + toNumOrZero(x), 0);
        const resultsMap = new Array<{ label: string; selected: boolean; votes: number; progress: number }>();
        buttons.forEach((btn: any) => {
            if (btn.type === 'votingButton') {
                const voteCount = toNumOrZero(results[btn.normalStyle.label]);
                resultsMap.push({
                    label: btn.normalStyle.label,
                    selected: this.voteCast === btn.normalStyle.label,
                    votes: voteCount,
                    progress: Math.round((voteCount / this.totalVotes) * 100)
                });
            }
        });
        return resultsMap;
    }

    public async pollUnsubscribe () {
        if (this._isSubscribed) {
            this._isSubscribed = false;
            await LiveApi.unsubscribe(this._eventMap);
            this._eventMap = null;
        }
        return this;
    }
}
